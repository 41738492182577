/*
 * @Author: goldeneyes
 * @Date: 2021-10-29 17:43:54
 * @LastEditTime: 2022-11-30 23:04:17
 * @lastEditors: goldeneyes
 * @Description: 校验相关工具
 */

import { isBoolean } from 'lodash'

/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:|\/\/)/.test(path)
}

/**
 * @description 校验密码是否小于6位
 * @param value
 * @returns {boolean}
 */
export function isPassword(value) {
    return value.length >= 6
}

/**
 * @description 判断是否为整数数字
 * @param value
 * @returns {boolean}
 */
export function isNumber(value) {
    const reg = /^[0-9]*$/
    return reg.test(value)
}

/**
 * @description 判断是否是名称
 * @param value
 * @returns {boolean}
 */
export function isName(value) {
    const reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/
    return reg.test(value)
}

/**
 * @description 判断是否为IP
 * @param ip
 * @returns {boolean}
 */
export function isIP(ip) {
    const reg =
        /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    return reg.test(ip)
}

/**
 * @description 判断是否是传统网站
 * @param url
 * @returns {boolean}
 */
export function isUrl(url) {
    const reg =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return reg.test(url)
}

/**
 * @description 判断是否是字母和数字
 * @param value
 * @returns {boolean}
 */
export function isLetterDigit(value) {
    const reg = /^[a-z0-9A-Z]+$/
    return reg.test(value)
}

/**
 * @description 判断是否是小写字母
 * @param value
 * @returns {boolean}
 */
export function isLowerLetter(value) {
    const reg = /^[a-z]+$/
    return reg.test(value)
}

/**
 * @description 判断是否是大写字母
 * @param value
 * @returns {boolean}
 */
export function isUpperLetter(value) {
    const reg = /^[A-Z]+$/
    return reg.test(value)
}

/**
 * @description 判断是否是大写字母开头
 * @param value
 * @returns {boolean}
 */
export function isAlphabets(value) {
    const reg = /^[A-Za-z]+$/
    return reg.test(value)
}

/**
 * @description 判断是否是字符串
 * @param value
 * @returns {boolean}
 */
export function isString(value) {
    return typeof value === 'string' || value instanceof String
}

/**
 * @description 判断是否为对象
 * @param arg
 * @returns {boolean}
 */
export function isObject(arg) {
    return Object.prototype.toString.call(arg) === '[object Object]'
}

/**
 * @description 判断是否是数组
 * @param arg
 */
export function isArray(arg) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
    return Array.isArray(arg)
}

/**
 * @description 判断是否是端口号
 * @param value
 * @returns {boolean}
 */
export function isPort(value) {
    const reg = /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/
    return reg.test(value)
}

/**
 * @description 判断是否是手机号
 * @param value
 * @returns {boolean}
 */
export function isPhone(value) {
    const reg = /^1\d{10}$/
    return reg.test(value)
}

/**
 * @description 判断是否是身份证号(第二代)
 * @param value
 * @returns {boolean}
 */
export function isIdCard(value) {
    const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    return reg.test(value)
}

/**
 * @description 判断是否是邮箱
 * @param value
 * @returns {boolean}
 */
export function isEmail(value) {
    const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    return reg.test(value)
}

/**
 * @description 判断是否中文
 * @param value
 * @returns {boolean}
 */
export function isChina(value) {
    const reg = /^[\u4E00-\u9FA5]{2,4}$/
    return reg.test(value)
}

/**
 * @description 判断是否为空
 * @param value
 * @returns {boolean}
 */
export function isEmpty(value) {
    switch (typeof value) {
        case 'undefined':
            return true
        case 'string':
            if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true
            break
        case 'boolean':
            if (!value) return true
            break
        case 'number':
            if (isNaN(value)) return true
            break
        case 'object':
            if (null === value || value.length === 0) return true
            if (value instanceof Date) return false
            for (var i in value) {
                return false
            }
            return true
    }
    return false
}

/**
 * @description 判断是否为固话
 * @param value
 * @returns {boolean}
 */
export function isTel(value) {
    const reg = /^(400|800)([0-9\\-]{7,10})|(([0-9]{4}|[0-9]{3})([- ])?)?([0-9]{7,8})(([- 转])*([0-9]{1,4}))?$/
    return reg.test(value)
}

/**
 * @description 判断是否为数字且最多两位小数
 * @param value
 * @returns {boolean}
 */
export function isNum(value) {
    const reg = /^\d+(\.\d{1,2})?$/
    return reg.test(value)
}

/**
 * @description 判断是否为小数且不限位数
 * @param value
 * @returns {boolean}
 */
export function isFloat(value) {
    const reg = /^[0-9]+\.?[0-9]+?$/
    return reg.test(value)
}

/**
 * @description 判断是否为日期类型的字符串
 * @param value
 * @returns {boolean}
 */
export function isDate(value) {
    var reg = /^[0-9,/:-\s]+$/
    if (!isNaN(Date.parse(new Date(value.replace(/-/g, '/')))) && isNaN(value) && reg.test(value)) {
        return true
    }
    return false
}

/**
 * @description 判断是否为json
 * @param value
 * @returns {boolean}
 */
export function isJson(value) {
    if (typeof value === 'string') {
        try {
            var obj = JSON.parse(value)
            if (typeof obj === 'object' && obj) {
                return true
            } else {
                return false
            }
        } catch (e) {
            return false
        }
    }
}

/**
 * @description 判断是否为枚举
 * @param value
 * @returns {boolean}
 */
export function isEnum(value) {
    if (typeof value === 'string') {
        try {
            var num = parseInt(value)
            if (typeof num === 'number' && num) {
                return true
            } else {
                return false
            }
        } catch (e) {
            return false
        }
    }
}

/**
 * @description 判断是否为方法，并执行
 */
export function execFunction(fun, ...data) {
    try {
        if (typeof fun === 'string') {
            return fun
        } else if (fun) {
            if (typeof fun === 'function') {
                if (this && this.row) {
                    return fun(this.row, ...data)
                } else {
                    return fun(...data)
                }
            } else {
                return fun
            }
        } else {
            return false
        }
    } catch (e) {
        console.error(e)
    }
}

/**
 * @description 布尔判断，默认为true，支持fun
 */
export function execBoolean(fun, defaultValue = true, ...data) {
    try {
        if (typeof fun === 'boolean') {
            return fun
        } else if (typeof fun === 'function') {
            if (this && this.row) return fun(this.row, ...data)
            else return fun(...data)
        } else {
            return defaultValue
        }
    } catch (e) {
        console.error(e)
    }
}

/**
 * 转换数据为字符串
 * @param {*} data 数据
 * @returns 字符串
 */
export function toString(data) {
    try {
        if (!isString(data)) {
            if (isNumber(data)) return data + ''
            else if (isBoolean(data)) return data ? 'TRUE' : 'FALSE'
            else if (isObject(data)) return ''
            else if (isArray(data)) return ''
        } else {
            return data
        }
    } catch (e) {
        console.error(e)
    }
}

/**
 * 转换数据为数字
 * @param {*} data 数据
 * @returns 数字
 */
export function toNumber(data) {
    try {
        if (!isNumber(data)) {
            if (isEnum(data)) return parseInt(data)
            else if (isString(data)) {
                if (!isNaN(parseFloat(data))) return parseFloat(data)
                else return 0
            } else if (isBoolean(data)) return data ? 1 : 0
            else if (isObject(data)) return 0
            else if (isArray(data)) return 0
        } else {
            return data
        }
    } catch (e) {
        console.error(e)
    }
}

/**
 * 转换数据为布尔
 * @param {*} data 数据
 * @returns 布尔
 */
export function toBoolean(data) {
    try {
        if (!isBoolean(data)) {
            if (isNumber(data)) return data === 1 ? true : false
            else if (isString(data)) return data === 'true' || data === 'TRUE' ? true : false
            else if (isObject(data)) return false
            else if (isArray(data)) return false
        } else {
            return data
        }
    } catch (e) {
        console.error(e)
    }
}
/**
 * 转换数据
 * @param {*} data 数据
 * @param {*} type 类型
 * @returns 转换后数据
 */
export function transform(data, type) {
    try {
        switch (type) {
            case 'string':
                return toString(data)
            case 'number':
                return toNumber(data)
            case 'boolean':
                return toBoolean(data)
            case 'enum':
                if (toNumber(data)) return toNumber(data) + ''
                else return ''
        }
    } catch (e) {
        console.error(e)
    }
}
