<!--
 * @Author: goldeneyes.li
 * @Date: 2022-12-11 02:14:17
 * @LastEditTime: 2024-12-02 10:34:28
 * @LastEditors: goldeneyes.li
 * @Description: 作品上傳组件
-->
<template>
    <div class="work-uploader">
        <el-tag
            v-if="userInfo.enterer === 'true' || userInfo.crenterer === 'true' || isManage"
            class="tip"
            type="warning"
        >
            <Icon icon="ri-information-line" />
            <span>{{ $t('只允許上傳zip/rar/pdf類型文件') }}</span>
        </el-tag>
        <el-form :disabled="uploading.length">
            <div
                :class="[
                    'd-flex pb-2',
                    { 'pt-5': userInfo.enterer === 'true' || userInfo.crenterer === 'true' || isManage },
                ]"
            >
                <el-button type="success" @click="handleAdd">
                    <Icon icon="ri-add-line" />
                    <span>{{ $t('添加冊數') }}</span>
                </el-button>
            </div>
            <el-table :data="book" height="450" stripe style="width: 100%">
                <el-table-column :label="$t('冊數')" prop="fencename" width="80" />
                <el-table-column :label="$t('預覽')" width="80">
                    <template #default="scope">
                        <el-image
                            class="thumb"
                            fit="cover"
                            :src="
                                scope.row.imgurl
                                    ? `${s3URL}${scope.row.imgurl.split(',')[0]}/w=100,h=120`
                                    : '/static/images/nothumb.jpg'
                            "
                            @click="preview(scope.row.imgurl)"
                        />
                    </template>
                </el-table-column>
                <el-table-column :label="$t('標題')">
                    <template #default="scope">
                        <el-input v-model="scope.row.zhuti" clearable :placeholder="$t('请输入标题')" />
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="userInfo.enterer === 'true' || userInfo.crenterer === 'true' || isManage"
                    :label="$t('打包上傳')"
                    width="240"
                >
                    <template #default="scope">
                        <el-progress
                            v-if="uploading.length && scope.row.file.length"
                            :percentage="scope.row.progress"
                        />
                        <el-upload
                            v-if="!uploading.length"
                            v-model:file-list="scope.row.file"
                            action=""
                            :auto-upload="false"
                            :class="scope.row.file.length ? 'has-file' : ''"
                            :limit="1"
                            :multiple="false"
                            :on-change="beforeUpload"
                        >
                            <template #trigger>
                                <el-button link type="primary">
                                    <Icon :icon="scope.row.imgurl ? 'ri-restart-line' : 'ri-folder-zip-line'" />
                                    <span>{{ scope.row.imgurl ? $t('重新上傳') : $t('選擇文件') }}</span>
                                </el-button>
                            </template>
                        </el-upload>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('操作')" width="200">
                    <template #default="scope">
                        <el-button :disabled="!scope.row.zhuti" type="primary" @click="handleEdit(scope.row.id)">
                            <Icon icon="ri-image-add-fill" />
                            <span>{{ $t('上傳圖片') }}</span>
                        </el-button>
                        <el-button
                            v-if="scope.$index === book.length - 1"
                            type="danger"
                            @click="handleRemove(scope.$index, scope.row)"
                        >
                            <Icon icon="ri-delete-bin-2-line" />
                            <span>{{ $t('刪除') }}</span>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div v-if="needUpload" class="d-flex justify-content-center pt-5">
                <el-button :loading="uploading.length" size="large" type="primary" @click="startUpload">
                    <Icon v-show="!uploading.length" icon="ri-contacts-book-upload-line" />
                    <span>{{ uploading.length ? $t('上傳中，請勿關閉页面...') : $t('开始上傳') }}</span>
                </el-button>
            </div>
        </el-form>
        <ImagesEditor ref="imagesEditorRef" @update="getBookData()" />
    </div>
</template>

<script setup name="WorkUploader">
    import { ref, toRef, reactive, onMounted, watch, computed } from 'vue'
    import { api as viewerApi } from 'v-viewer'
    import { getZuopinByid, addFenceByid, deleteFenceByid } from '@/api/book'
    import ImagesEditor from './ImagesEditor'
    import { cloneDeep } from '@/utils/convert'
    import { uploadFileSilce } from '@/utils/upload'
    import { isEmpty } from '@/utils/validate'
    import { useUserStore } from '@/store/modules/user'
    import { gp } from '@gp'

    const props = defineProps({
        // 当前作品id
        bookId: {
            type: String,
            default: '',
        },
        // 文件上傳大小限制
        fileSizeLimit: {
            type: Number,
            default: 2000,
        },
        // 上傳文件字段名
        fileFieldName: {
            type: String,
            default: 'file',
        },
        // 文件上傳类型限制
        fileTypeLimit: {
            type: Array,
            default: () => {
                return ['zip', 'rar', 'pdf']
            },
        },
    })
    const emit = defineEmits(['complete', 'fileRemoved'])

    const { getUserInfo: userInfo } = useUserStore()

    const id = ref(props.bookId)
    const s3URL = window.global.s3URL
    let book = reactive([])
    const needUpload = computed(() => {
        return book.some((item) => {
            return item.file.length > 0
        })
    })
    const uploading = reactive([]) // 记录正在上傳的文件

    // 作品图片编辑组件
    const imagesEditorRef = ref(null)

    /**
     * 获取作品信息
     */
    const getBookData = async () => {
        book.length = 0
        const { data } = await getZuopinByid(id.value)
        if (data && data.listImg?.length) {
            data.listImg.forEach((item) => {
                book.push({
                    ...item,
                    file: [],
                })
            })
            // 排序
            book.sort((a, b) => {
                return a.fencename - b.fencename
            })
        }
    }

    /**
     * 添加冊數
     */
    const handleAdd = async () => {
        gp.$basePrompt(
            '請輸入分冊名稱',
            false,
            async ({ action, value }) => {
                await addFenceByid(id.value, book.length + 1, value)
                await getBookData()
            },
            () => {},
            `添加冊數`
        )
    }

    /**
     * 编辑作品分册图片
     */
    const handleEdit = async (id) => {
        await getBookData()
        const row = book.find((item) => item.id === id)
        if (row) {
            imagesEditorRef.value.show(row)
        }
    }

    /**
     * 删除作品分册
     */
    const handleRemove = async (index, row) => {
        if (isEmpty(row.id)) {
            book.splice(index, 1)
            return
        }
        await gp
            .$confirm(`確定刪除第${row.fencename}冊嗎？`, '提示', {
                type: 'warning',
            })
            .then(async () => {
                await deleteFenceByid(row.zuopinid, row.id)
                book.splice(index, 1)
                emit('fileRemoved')
            })
    }

    // 预览
    const preview = (images) => {
        let _images = cloneDeep(images)
        _images = _images.split(',')
        _images.forEach((item, index) => {
            _images[index] = s3URL + item + '/public'
        })
        const $viewer = viewerApi({
            options: {
                toolbar: true,
                initialViewIndex: 0,
                zIndex: 9999,
            },
            images: _images,
        })
    }

    // 上傳检查
    const beforeUpload = (rawFile) => {
        if (!props.fileTypeLimit.includes(rawFile.name.split('.').pop().toLowerCase())) {
            gp.$baseMessage(gp.$t('只允許上傳zip,rar,pdf格式！'), 'error')
            book.forEach((item) => {
                if (item.file[0]?.raw === rawFile.raw) {
                    item.file = []
                }
            })
            return false
        } else if (rawFile.size > props.fileSizeLimit * 1024 * 1024) {
            gp.$baseMessage(gp.$t(`上傳文件大小不能超過${props.fileSizeLimit}Mb`), 'error')
            book.forEach((item) => {
                if (item.file[0]?.raw === rawFile.raw) {
                    item.file = []
                }
            })
            return false
        }
        return true
    }

    // 上傳
    const startUpload = async () => {
        let canUpload = true
        uploading.length = 0
        book.forEach((item) => {
            if (item.file.length === 0 && isEmpty(item.imgurl)) {
                gp.$baseMessage(gp.$t(`請選擇分册${item.fencename}文件！`), 'error')
                canUpload = false
            }
        })
        // 依次上傳
        if (canUpload) {
            book.forEach(async (item) => {
                if (item.file.length > 0) {
                    item.file.forEach((file) => {
                        uploading.push(
                            uploadFileSilce(file, toRef(item, 'progress'), {
                                fencename: item.fencename,
                                id: id.value,
                                zhuti: item.zhuti,
                            })
                        )
                    })
                }
            })
            try {
                await Promise.all(uploading)
                emit('complete')
                uploading.length = 0
            } catch (error) {
                console.log(error)
                uploading.length = 0
            }
        }
    }

    watch(
        () => props.bookId,
        (val) => {
            id.value = val
            getBookData()
        }
    )

    onMounted(() => {
        getBookData()
    })
</script>

<style lang="scss" scoped>
    .work-uploader {
        :deep() {
            .tip {
                font-size: var(--font-size-sm);
            }
            .el-upload-list {
                margin: 0;
            }
            .thumb {
                border: 3px solid #fff;
                width: 40px;
                height: 60px;
                box-shadow: var(--box-shadow-sm);
                border-radius: 4px;
                cursor: pointer;
            }
            .has-file {
                .el-upload {
                    display: none;
                }
            }
        }
    }
</style>
