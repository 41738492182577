/*
 * @Author: goldeneyes.li
 * @Date: 2022-10-31 16:58:52
 * @LastEditTime: 2022-11-12 17:32:02
 * @LastEditors: goldeneyes.li
 * @Description: 多语种配置
 */
import { createI18n } from 'vue-i18n'
import { defaultLanguage } from '@/config'

// 引入同级目录下文件
const modules = import.meta.globEager('./*.js')

function getLangAll() {
    let message = {}
    getLangFiles(modules, message)
    return message
}

/**
 * 获取所有语言文件
 * @param {Object} mList
 */
function getLangFiles(mList, msg) {
    for (let path in mList) {
        if (mList[path].default) {
            //  获取文件名
            let pathName = path.replace(/(\.\/|\.js)/g, '')

            if (msg[pathName]) {
                msg[pathName] = {
                    ...mList[pathName],
                    ...mList[path].default,
                }
            } else {
                msg[pathName] = mList[path].default
            }
        }
    }
}

// 当前设置的语言
function getCurrLanguage() {
    return localStorage.getItem('language') || defaultLanguage
}

//注册i8n实例并引入语言文件
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    global: true,
    locale: getCurrLanguage(),
    messages: getLangAll(),
})

export default i18n

// 当前语种名称
export function getCurrLangLabel(lang) {
    switch (lang) {
        case 'zh-TW':
            return '繁体中文'
        case 'en':
            return 'English'
        default:
            return '繁体中文'
    }
}
