/*
 * @Author: goldeneyes
 * @Date: 2021-04-29 17:43:54
 * @LastEditTime: 2022-12-04 11:17:11
 * @lastEditors: goldeneyes
 * @Description: 存储token相关工具
 */
import { tokenTableName } from '@/config'
import { safelyJSONParse } from './convert'

/**
 * @description 获取token
 * @returns {object} token
 */
export function getToken() {
    let token = safelyJSONParse(localStorage.getItem(tokenTableName))
    return token
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token) {
    token = JSON.stringify(token)
    return localStorage.setItem(tokenTableName, token)
}

/**
 * @description 移除token
 * @returns {void|*}
 */
export function removeToken() {
    return localStorage.removeItem(tokenTableName)
}
