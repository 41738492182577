<!--
 * @Author: goldeneyes.li
 * @Date: 2022-12-03 00:04:31
 * @LastEditTime: 2023-02-16 21:53:22
 * @LastEditors: goldeneyes.li
 * @Description: 漫画列表
-->
<template>
    <section class="book-list">
        <el-row v-if="data.length && !loading">
            <el-col
                v-for="(item, idx) in data"
                :key="idx"
                :lg="grid.lg"
                :md="grid.md"
                :sm="grid.sm"
                :xl="grid.xl"
                :xs="grid.xs"
            >
                <BookItem :book="item" :collect="collect" @removeCollect="removeCollect" />
            </el-col>
        </el-row>
        <el-row v-if="loading" :gutter="20">
            <el-col
                v-for="(item, idx) in skeleton"
                :key="idx"
                :lg="grid.lg"
                :md="grid.md"
                :sm="grid.sm"
                :xl="grid.xl"
                :xs="grid.xs"
            >
                <Skeleton />
            </el-col>
        </el-row>
        <!-- <el-empty
            v-else
            class="empty"
            :description="$t('暫無數據')"
            image="/static/images/nodata.png"
            :image-size="120"
        /> -->
    </section>
</template>

<script setup name="BookList">
    const props = defineProps({
        grid: {
            type: Object,
            default() {
                return {
                    lg: 4,
                    md: 4,
                    sm: 8,
                    xl: 3,
                    xs: 12,
                }
            },
        },
        data: {
            type: Object || Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        skeleton: {
            type: Number,
            default: 0,
        },
        collect: {
            type: Boolean,
            default: false,
        },
    })

    const emit = defineEmits(['removeCollect'])

    const removeCollect = (id) => {
        emit('removeCollect', id)
    }
</script>

<style lang="scss" scoped>
    .book-list {
        width: 100%;
        :deep() {
            .el-row {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }
            .empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: calc(100vh - $header-height - 120px);
            }
        }
    }
</style>
