<!--
 * @Author: goldeneyes.li
 * @Date: 2022-02-28 11:22:04
 * @LastEditors: goldeneyes.li
 * @LastEditTime: 2024-09-21 23:06:49
 * @Description: 用户头像
-->
<template>
    <el-menu :default-active="$route.path" :ellipsis="false" mode="horizontal" router>
        <el-sub-menu popper-class="avatar-submenu">
            <template #title>
                <!-- <el-avatar
                    class="avatar"
                    :size="32"
                    :src="`${s3URL}${userInfo.touxiang}/w=88,h=88` || '/static/images/avatar.jpg'"
                /> -->
                <span style="margin-left: 5px">{{ $t(userInfo.nicheng) }}</span>
            </template>
            <el-menu-item v-for="item in handleRoutes" :key="item.fullPath" :index="item.fullPath">
                <Icon :icon="item.meta.icon" />
                <span>{{ $t(item.meta.title) }}</span>
            </el-menu-item>
            <el-menu-item index="">
                <el-link type="danger" :underline="false" @click="handleLogout">
                    <Icon icon="ri-logout-circle-r-line" />
                    <span>{{ $t('退出登錄') }}</span>
                </el-link>
            </el-menu-item>
        </el-sub-menu>
    </el-menu>
</template>

<script setup name="Avatar">
    import { computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from '@/store/modules/user'
    import { finalRoutes } from '@/router'

    const props = defineProps({
        type: {
            type: String,
            default: 'default',
        },
    })

    const router = useRouter()
    const { getUserInfo: userInfo, logout } = useUserStore()
    const s3URL = window.global.s3URL

    /**
     * @description: 退出登录
     * @return {*}
     */
    const handleLogout = () => {
        logout()
        window.location.reload()
    }

    /**
     * @description: 查找用户页面
     * @return {*}
     */
    const handleRoutes = computed(() => {
        return finalRoutes
            .find((item) => item.path === '/user-center')
            .children.filter((route) => {
                if (userInfo.enterer !== 'true' && userInfo.crenterer !== 'true' && route.name === 'Work') {
                    return true
                } else {
                    return true
                }
            })
            .flatMap((route) => {
                return route
            })
    })
</script>

<style lang="scss" scoped></style>
