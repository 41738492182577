/*
 * @Author: goldeneyes
 * @Date: 2021-11-24 17:43:54
 * @LastEditTime: 2022-11-24 19:32:09
 * @lastEditors: goldeneyes
 * @Description: 路由相关工具
 */

import path from 'path'
import { isExternal } from '@/utils/validate'
import { recordRoute } from '@/config'

/**
 * @description 根据roles数组拦截路由
 * @param routes 路由
 * @param baseUrl 基础路由
 * @returns {[]}
 */
export function filterRoutes(routes, baseUrl = '/') {
    return routes.map((route) => {
        if (route.path !== '*' && !isExternal(route.path)) route.fullPath = path.resolve(baseUrl, route.path)
        if (route.children) route.children = filterRoutes(route.children, route.fullPath)
        return route
    })
}

/**
 * 根据当前route获取激活菜单
 * @param route 当前路由
 * @param isTabsBar 是否是标签
 * @returns {string|*}
 */
export function handleActivePath(route, isTabsBar = false) {
    const { meta, fullPath } = route
    const rawPath = route.matched ? route.matched[route.matched.length - 1].path : fullPath
    if (isTabsBar) return meta.dynamicNewTab ? fullPath : rawPath
    if (meta.activeMenu) return meta.activeMenu
    return fullPath ? fullPath : rawPath
}

/**
 * 获取当前跳转登录页的Route
 * @param currentPath 当前页面地址
 */
export function toLoginRoute(currentPath) {
    if (recordRoute && currentPath !== '/')
        return {
            path: '/login',
            query: { redirect: currentPath },
            replace: true,
        }
    else return { path: '/login', replace: true }
}

/**
 * 获取路由中所有的Name
 * @param routes 路由数组
 * @returns {*} Name数组
 */
export function getNames(routes) {
    return routes.flatMap((route) => {
        let names = []
        if (route.name) names.push(route.name)
        if (route.children) names.push(...getNames(route.children))
        return names
    })
}
