/*
 * @Author: goldeneyes
 * @Date: 2021-04-29 17:43:54
 * @LastEditTime: 2023-02-05 01:58:57
 * @lastEditors: goldeneyes
 * @Description: 路由守卫
 */
import router from '@/router'
import Progress from 'nprogress'
import 'nprogress/nprogress.css'
import { useUserStore } from '@/store/modules/user'
import { useTitle } from '@vueuse/core'
import { toLoginRoute } from '@/utils/routes'
import { isEmpty } from '@/utils/validate'
import { getPageTitle } from '@/utils/pageTitle'
import { routesAuthList } from '@/config'

export function setupPermissions() {
    Progress.configure({
        easing: 'ease',
        speed: 500,
        trickleSpeed: 200,
        showSpinner: false,
    })
    router.beforeEach(async (to, from) => {
        Progress.start()
        // url参数包含token,用token登录
        if (to.query.token) {
            useUserStore().setToken(to.query.token)
        }
        let hasToken = useUserStore().isAuthenticated
        if (!isEmpty(hasToken)) {
            // 禁止已登录用户返回登录页
            if (to.path === '/login') {
                Progress.done()
                return { path: '/' }
            } else return true
        } else {
            let goLogin
            routesAuthList.forEach((item) => {
                if (to.path.search(item) !== -1) {
                    goLogin = true
                }
            })
            if (goLogin) {
                return toLoginRoute(to.path)
            } else {
                return true
            }
        }
    })
    router.afterEach((to) => {
        useTitle(getPageTitle(to.meta.title))
        if (Progress.status) Progress.done()
    })
}
