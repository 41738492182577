/*
 * @Author: goldeneyes
 * @Date: 2021-10-29 17:43:54
 * @LastEditTime: 2024-11-13 15:29:08
 * @lastEditors: goldeneyes
 * @Description: 全局通用配置
 */

// 应用名称
const appName = '漫畫花園'
// 简写
const appAbbreviation = '漫畫花園 ComicGarden'
// 版权信息
const copyright = 'www.comicgarden.com 漫畫花園'
// 默认语言类型zh-TW、en
const defaultLanguage = 'zh-TW'
// 路由模式，是否为hash模式
const isHashRouterMode = true
// 需要登录的路由
const routesAuthList = [
    '/user-center',
    '/user-center/user-info',
    '/user-center/history',
    '/user-center/shelf',
    '/user-center/work',
    '/user-center/collect',
    '/manage',
    '/manage/addWork',
    '/manage/review',
    '/manage/complaint',
]
// 开启vue前台页（不开启默认用php渲染首页等路由）
const enableVueFront = false
// 加载时显示文字
const loadingText = 'Loading...'
// 登录模式(jwt或传统cookie)
const loginMode = 'jwt'
// 请求header中的token名称
const tokenName = 'token'
// token在localStorage、sessionStorage、cookie存储的key的名称
const tokenTableName = 'access-token'
// token存储位置localStorage sessionStorage cookie
const storage = 'localStorage'
// token失效，退出到登录页时是否记录当前路由
const recordRoute = true
// 消息框消失时间（毫秒）
const messageDuration = 3000

export {
    appName,
    appAbbreviation,
    copyright,
    defaultLanguage,
    isHashRouterMode,
    enableVueFront,
    routesAuthList,
    loadingText,
    loginMode,
    tokenName,
    tokenTableName,
    storage,
    recordRoute,
    messageDuration,
}
