<!--
 * @Author: goldeneyes.li
 * @Date: 2022-12-03 00:04:06
 * @LastEditTime: 2024-11-13 15:34:49
 * @LastEditors: goldeneyes.li
 * @Description: 漫画
-->
<template>
    <div class="book-item">
        <div class="thumb">
            <el-tag v-if="book.worktype == '1'" class="ifend" :type="book.ifend == '1' ? 'success' : 'warning'">
                {{ book.ifend == '1' ? $t('已完結') : $t('連載中') }}
            </el-tag>
            <el-image
                :src="
                    book.cover
                        ? `${s3URL}${book.cover}/w=200,h=300`
                        : book.imgurl
                        ? `${s3URL}${book.imgurl}/w=200,h=300`
                        : '/static/images/nothumb.jpg'
                "
            />
            <div class="desc" @click="read(book)">
                <h5 class="name">{{ book.name }}</h5>
                <p>{{ book.miaoshu }}</p>
                <el-button type="danger">
                    <i class="ri-book-open-line"></i>
                    <span>{{ $t('開始閱讀') }}</span>
                </el-button>
            </div>
        </div>
        <h5 class="name">{{ book.name }}</h5>
        <div class="type">
            {{ book.fenlei }} /
            <i class="ri-earth-line"></i>
            <span>{{ book.yuyan }}</span>
            <el-tag v-if="book.status == '0'" style="margin-left: 5px" type="danger">{{ $t('已下架') }}</el-tag>
        </div>
        <div class="tags">
            <el-tag v-for="(tag, idx) in book.guanfangtag?.split(',').filter((item) => item != '')" :key="idx">
                {{ tag }}
            </el-tag>
        </div>
        <div class="count">
            <el-button class="like" :class="{ ifzan: book.ifzan === 'Y' }" link :loading="liking" @click="like(book)">
                <Icon :icon="book.ifzan === 'Y' ? 'ri-thumb-up-fill' : 'ri-thumb-up-line'" />
                ({{ book.zan }})
            </el-button>
            <div class="view ms-3" link>
                <Icon icon="ri-eye-2-line" />
                ({{ book.view || 0 }})
            </div>
        </div>
        <el-button size="large" type="danger" @click="read(book)">
            <i class="ri-book-open-line"></i>
            <span>{{ $t('開始閱讀') }}</span>
        </el-button>
        <el-button v-if="collect" class="mt-2" size="large" text @click="removeCollect(book.id)">
            <i class="ri-dislike-line"></i>
            <span>{{ $t('取消收藏') }}</span>
        </el-button>
    </div>
</template>

<script setup name="BookItem">
    import { ref } from 'vue'
    import router from '@/router'
    import { useUserStore } from '@/store/modules/user'
    import { deleteShujia } from '@/api/user'
    import { addZanComic, deleteZanComic } from '@/api/book'
    import { enableVueFront } from '@/config'
    import { gp } from '@gp'

    const emit = defineEmits(['removeCollect'])

    const { isAuthenticated } = useUserStore()

    const s3URL = window.global.s3URL

    const props = defineProps({
        // 漫画数据
        book: {
            type: Object,
            required: true,
        },
        // 是否作品编辑
        edit: {
            type: Boolean,
            default: false,
        },
        // 是否收藏
        collect: {
            type: Boolean,
            default: false,
        },
    })

    const liking = ref(false)

    // 点赞/取消点赞
    const like = async (book) => {
        if (!isAuthenticated) {
            gp.$baseMessage(gp.$t('請先登錄'), 'warning')
            return
        }
        try {
            liking.value = true
            if (book.ifzan === 'Y') {
                await deleteZanComic(book.id)
                book.ifzan = 'N'
                book.zan--
            } else {
                await addZanComic(book.id)
                book.ifzan = 'Y'
                book.zan++
            }
        } finally {
            liking.value = false
        }
    }

    // 移除收藏x
    const removeCollect = async (id) => {
        await deleteShujia(id)
        gp.$baseMessage(gp.$t('已取消收藏'), 'success')
        emit('removeCollect', id)
    }

    // 跳转到漫画详情页
    const read = (item) => {
        if (enableVueFront) {
            const url = router.resolve({
                path: `/reader/${item.id}`,
            })
            window.open(url.href, '_blank')
        } else {
            window.open(`${window.location.origin}/workDetail?id=${item.id}`, '_blank')
        }
    }
</script>

<style lang="scss" scoped>
    .book-item {
        flex: 0;
        width: 100%;
        max-width: 200px;
        padding: 10px;
        border-radius: 4px;
        :deep() {
            .thumb {
                position: relative;
                cursor: pointer;
                .ifend {
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 0 0 6px 0;
                    z-index: 1;
                }
                .el-image {
                    width: 100%;
                    height: 240px;
                    border: 4px solid #fff;
                    box-shadow: var(--box-shadow-sm);
                    border-radius: 6px;
                }
                .desc {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    height: 0;
                    padding: 0;
                    overflow: hidden;
                    background: rgba(0, 0, 0, 0.7);
                    backdrop-filter: blur(8px);
                    .name {
                        margin: 0;
                        margin-bottom: 5px;
                        font-size: var(--base-font-size);
                        color: #fff;
                        @include ellipsis(1);
                    }
                    p {
                        font-size: var(--font-size-sm);
                        color: #fff;
                        @include ellipsis(3);
                    }
                    .el-button {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        left: 10px;
                    }
                }
                &:hover {
                    transition: var(--transition-base);
                    transform: scale(1.1) translateY(-5px);
                    .el-image {
                        border: 4px solid #fff;
                        box-shadow: var(--box-shadow-lg);
                        transition: var(--transition-base);
                    }
                    .desc {
                        height: 60%;
                        padding: var(--padding-sm);
                        transition: var(--transition-base);
                    }
                }
            }
            .name {
                margin: 5px 0 0 0;
                font-size: var(--base-font-size);
                line-height: 1.4;
                @include ellipsis(2);
            }
            .type {
                padding-top: 5px;
                font-size: var(--font-size-sm);
                color: var(--text-normal);
            }
            .tags {
                display: none;
                margin: 5px 0;
                .el-tag {
                    margin: 3px 3px 0 0;
                }
            }
            .count {
                display: flex;
                align-items: center;
                .like {
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    font-size: var(--font-size-sm);
                    color: var(--text-normal);
                    .icon {
                        margin-right: 3px;
                    }
                    &.ifzan {
                        .icon {
                            color: $danger;
                        }
                    }
                }
                .view {
                    font-size: var(--font-size-sm);
                    color: var(--text-normal);
                }
            }
            .el-button {
                display: block;
                & + .el-button {
                    margin: 0;
                }
            }
        }
    }
</style>
