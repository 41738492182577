<!--
 * @Author: goldeneyes.li
 * @Date: 2022-02-28 11:22:04
 * @LastEditors: goldeneyes.li
 * @LastEditTime: 2022-11-20 09:47:19
 * @Description: 图标控件
-->
<template>
    <img v-if="isImg" class="icon" :src="imgSrc" />
    <svg v-else-if="isSvg" aria-hidden="true" class="icon">
        <use :xlink:href="iconName" />
    </svg>
    <i v-else aria-hidden="true" class="icon" :class="icon"></i>
</template>

<script setup name="Icon">
    import { computed } from 'vue'

    const props = defineProps({
        // 图标名称
        icon: {
            type: String,
            required: true,
        },
        imgSrc: String,
        isImg: Boolean, // 是否是图片
        isSvg: Boolean, // 是否是svg
    })

    const iconName = computed(() => {
        return `#icon-${props.icon}`
    })
</script>

<style lang="scss" scoped>
    [class*='ri-'] {
        display: inline-block;
        font-size: var(--font-size-lg);
        text-align: center;
        vertical-align: -4px;
    }
    svg {
        width: 20px;
        height: 20px;
        vertical-align: -4px;
    }
</style>
