/*
 * @Author: goldeneyes.li
 * @Date: 2022-12-03 21:55:54
 * @LastEditTime: 2023-03-11 23:39:04
 * @LastEditors: goldeneyes.li
 * @Description: 标签相关接口
 */
import request from '@/utils/request'

/**
 * @description 获得官方tag
 */
export function getTagList() {
    return request({
        url: '/getTagList',
        method: 'post',
        loading: false,
    })
}

/**
 * @description 获得用户tag
 */
export function getUserTagList() {
    return request({
        url: '/getTagList',
        method: 'post',
        params: {
            tagname: 'w',
        },
        loading: false,
    })
}
