<!--
 * @Author: goldeneyes.li
 * @Date: 2022-10-31 16:15:52
 * @LastEditTime: 2023-01-10 00:43:52
 * @LastEditors: goldeneyes.li
 * @Description: 底部组件
-->
<template>
    <el-footer class="footer">
        <div class="container">
            <div class="copyright">&copy; {{ date }} {{ appName }}</div>
        </div>
    </el-footer>
</template>

<script setup name="Footer">
    import { ref } from 'vue'
    import { appName, copyright } from '@/config'
    import { formatTime } from '@/utils/date'

    const props = defineProps({})
    // 版权时间
    const date = ref(formatTime(new Date(), 'YYYY'))
</script>

<style lang="scss" scoped>
    .el-footer.footer {
        position: relative;
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 0;
            padding-bottom: 0;
            ::v-deep() {
                .logo {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 0;
                    img {
                        width: 80px;
                    }
                    h1 {
                        padding: 0;
                        margin: 0;
                        font-size: var(--font-size-ex);
                    }
                }
                .search {
                    width: 640px;
                    max-width: 80%;
                    margin-bottom: 30px;
                }
                .copyright {
                    width: 100%;
                    padding: var(--padding-sm);
                    font-size: var(--font-size-sm);
                    text-align: center;
                }
            }
        }
    }
</style>
