/*
 * @Author: goldeneyes
 * @Date: 2021-10-29 17:43:54
 * @LastEditTime: 2022-12-10 23:23:52
 * @lastEditors: goldeneyes
 * @Description: 多语种中文对照
 */
export default {
    漫畫花園: '漫畫花園',
    首頁: '首頁',
    版權所有: '版權所有',
    用戶登錄: '用戶登錄',
    登錄中: '登錄中',
    退出登錄: '退出登錄',
    用戶名: '用戶名',
    昵稱: '昵稱',
    密碼: '密碼',
    新密碼: '新密碼',
    請輸入用戶名: '請輸入用戶名',
    輸入新密碼: '輸入新密碼',
    再次輸入新密碼: '再次輸入新密碼',
    請輸入密碼: '請輸入密碼',
    請輸入驗證碼: '請輸入驗證碼',
    立即註冊會員: '立即註冊會員',
    創建賬號: '創建賬號',
    不能為空: '不能為空',
    海量免費漫畫: '海量免費漫畫',
    '已有賬號？立即登錄': '已有賬號？立即登錄',
    '還未註冊？立即註冊': '還未註冊？立即註冊',
    '現在加入，海量漫畫免費看': '現在加入，海量漫畫免費看',
    '作品搜索：': '作品搜索：',
    '搜索你喜歡的漫畫/作者': '搜索你喜歡的漫畫/作者',
    近期更新: '近期更新',
    同人誌: '同人誌',
    CG畫冊: 'CG畫冊',
    單行本: '單行本',
    國漫: '國漫',
    韓漫: '韓漫',
    Cosplay: 'Cosplay',
    新用戶註冊: '新用戶註冊',
    登錄: '登錄',
    用戶信息: '用戶信息',
    個人信息: '個人信息',
    觀看歷史: '觀看歷史',
    我的書架: '我的書架',
    我的上傳: '我的上傳',
    我的收藏: '我的收藏',
    您有新的消息: '您有新的消息',
    '人氣排行榜(Top10)': '人氣排行榜(Top10)',
    漫畫列表: '漫畫列表',
    最近更新: '最近更新',
    熱門連載: '熱門連載',
    開始閱讀: '開始閱讀',
    题材: '题材',
    熱血: '熱血',
    戀愛: '戀愛',
    搞笑: '搞笑',
    校園: '校園',
    科幻: '科幻',
    魔法: '魔法',
    冒險: '冒險',
    競技: '競技',
    治愈: '治愈',
    懸疑: '懸疑',
    戰爭: '戰爭',
    歷史: '歷史',
    機戰: '機戰',
    耽美: '耽美',
    少女: '少女',
    少年: '少年',
    後宮: '後宮',
    萌系: '萌系',
    推理: '推理',
    恐怖: '恐怖',
    勵誌: '勵誌',
    運動: '運動',
    職場: '職場',
    穿越: '穿越',
    奇幻: '奇幻',
    進度: '進度',
    連載中: '連載中',
    已完结: '已完结',
    受眾: '受眾',
    少兒: '少兒',
    青少年: '青少年',
    成人: '成人',
    首字母: '首字母',
    全部: '全部',
    用戶中心: '用戶中心',
    添加分類: '添加分類',
    添加標簽: '添加標簽',
}
