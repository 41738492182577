/*
 * @Author: goldeneyes.li
 * @Date: 2022-12-11 00:56:57
 * @LastEditTime: 2023-04-21 20:16:01
 * @LastEditors: goldeneyes.li
 * @Description: 设置标题
 */
import { useI18n } from 'vue-i18n'
import { appAbbreviation } from '@/config'

/**
 * @description 设置标题
 * @param pageTitle
 * @returns {string}
 */
export function getPageTitle(pageTitle) {
    // const { t, te } = useI18n({ useScope: 'global' })
    // if (te(`${pageTitle}`)) pageTitle = t(`${pageTitle}`)
    let newTitles = []
    if (appAbbreviation) newTitles.push(appAbbreviation)
    if (pageTitle) newTitles.push(pageTitle)
    return newTitles.join(' | ')
}
