/*
 * @Author: goldeneyes.li
 * @Date: 2023-02-18 23:22:08
 * @LastEditTime: 2024-08-12 18:07:00
 * @LastEditors: goldeneyes.li
 * @Description: 上傳方法
 */
import { upload, check } from '@/api/book'
import { getMD5 } from '@/utils/convert'
import { gp } from '@gp'

/**
 * @description: 分片函数
 * @param file 文件
 * @param progress 进度
 * @param params 参数
 */
export function uploadFileSilce(file, progress, params) {
    return new Promise((resolve, reject) => {
        // 文件名
        const { name } = file
        // 文件大小
        const { size } = file
        // 分片大小
        const shardSize = 1024 * 1024 * 4
        // 分片总数
        const shardTotal = Math.ceil(size / shardSize)
        // 文件加密
        let key = ''
        const hash = getMD5(file).then(async (res) => {
            key = `${res}_${params.id}_${params.fencename}`
            // 调用文件校验接口
            const data = await check(key)
            if (data) {
                if (data.code === 500) {
                    // 如果文件不存在
                    progress.value = 0
                    // 调用分片函数
                    uploadFileSilce(file, 0)
                }
                if (data.code === 200) {
                    // 如果文件已存在
                    if (data?.data?.shardIndex === data?.data?.shardTotal) {
                        progress.value = 100
                        return resolve('上傳完成')
                    } else {
                        // 断点续传
                        uploadFileSilce(file, data?.data?.shardIndex)
                    }
                }
            }
        })
        const uploadFileSilce = async (file, shardIndex) => {
            // 如果 当前分片索引 大于 总分片数
            if (shardIndex >= shardTotal) {
                console.log('上傳完成')
                progress.value = 100
                return resolve('上傳完成')
            }

            // 文件开始结束的位置
            const start = shardIndex * shardSize
            const end = Math.min(start + shardSize, size)
            // 开始切割
            const packet = file.raw.slice(start, end)

            // 拼接请求参数
            const formData = new FormData()
            formData.append('zuopinId', params.id)
            formData.append('fenceName', params.fencename)
            formData.append('zhuti', params.zhuti)
            formData.append('file', packet)
            formData.append('suffix', name.split('.').pop())
            formData.append('shardIndex', shardIndex + 1)
            formData.append('shardSize', shardSize)
            formData.append('shardTotal', shardTotal)
            formData.append('size', size)
            formData.append('key', key)

            // 如果 当前分片索引 小于 总分片数
            if (shardIndex < shardTotal) {
                // 进度条保留两位小数展示
                progress.value = Number(((shardIndex / shardTotal) * 100).toFixed(0)) * 1
                // 调用文件上傳接口
                try {
                    const res = await upload(formData)
                    if (res !== '上傳成功') {
                        gp.$message.error('上傳失败')
                        progress.value = 0
                        return reject(res)
                    }
                    if (res === '上傳成功') {
                        // 这里为所有切片上傳成功后进行的操作
                        console.log(`已上傳切片：${shardIndex + 1}/${shardTotal}`)
                    }
                    shardIndex++
                    // 递归调用 分片函数
                    uploadFileSilce(file, shardIndex)
                } catch (error) {
                    gp.$message.error('上傳失败，请重试')
                    return reject()
                }
            }
        }
    })
}
