/*
 * @Author: goldeneyes.li
 * @Date: 2022-11-30 13:38:29
 * @LastEditTime: 2022-11-30 13:41:35
 * @LastEditors: goldeneyes.li
 * @Description: 导入所有 pinia 模块
 */
import { createPinia } from 'pinia'

const pinia = createPinia()

export function setupStore(app) {
    app.use(pinia)
}

export default pinia
