/*
 * @Author: goldeneyes.li
 * @Date: 2022-12-04 11:21:27
 * @LastEditTime: 2024-12-08 19:21:38
 * @LastEditors: goldeneyes.li
 * @Description: 作品相关接口
 */
import request from '@/utils/request'
import language from 'jian_fan'

/**
 * @description 获得分类列表
 */
export function getFenleiList() {
    return request({
        url: '/getFenleiList',
        method: 'post',
        loading: false,
    })
}

/**
 * @description 获得top排名
 */
export function getTop(worktype) {
    return request({
        url: '/getTop',
        method: 'post',
        params: { worktype },
        loading: false,
    })
}

/**
 * @description 猜你喜欢
 */
export function getLike(worktype) {
    return request({
        url: '/getLike',
        method: 'post',
        params: { worktype },
        loading: false,
    })
}

/**
 * @description 热门连载
 */
export function getHot(worktype) {
    return request({
        url: '/getHot',
        method: 'post',
        params: { worktype },
        loading: false,
    })
}

/**
 * @description 其他人喜欢
 */
export function getOtherLike(zuopinid) {
    return request({
        url: '/getGuessLike',
        method: 'post',
        params: { zuopinid },
        loading: false,
    })
}

/**
 * @description 查询作品列表
 * @params {*} pageIndex name guanfangtag fenlei
 */
export function getZuopinPage(params) {
    if (params?.name) {
        params.name = encodeURIComponent(params.name)
    }
    if (params?.guanfangtag) {
        params.guanfangtag = language.traditional(params.guanfangtag)
        params.guanfangtag = encodeURIComponent(params.guanfangtag)
    }
    if (params?.yonghutag) {
        params.yonghutag = language.traditional(params.yonghutag)
        params.yonghutag = encodeURIComponent(params.yonghutag)
    }
    return request({
        url: '/getZuopinPage',
        method: 'post',
        params,
        loading: false,
    })
}

/**
 * @description 查询用户作品列表
 * @params {*} userid 用户id
 */
export function getMyZhuyezuopin(params) {
    return request({
        url: '/getMyZhuyezuopin',
        method: 'post',
        params,
        loading: false,
    })
}

/**
 * @description 查询作品详情
 * @params {*} zuopinid 作品id
 */
export function getZuopinByid(zuopinid) {
    return request({
        url: '/getZuopinByid',
        method: 'post',
        params: {
            zuopinid,
        },
        loading: true,
    })
}

/**
 * @description 点赞作品
 * @params {*} zuopinid 作品id
 */
export function addZanComic(zuopinid) {
    return request({
        url: '/addZanComic',
        method: 'post',
        data: {
            zuopinid,
        },
        loading: false,
    })
}

/**
 * @description 取消点赞
 * @params {*} zuopinid 作品id
 */
export function deleteZanComic(zuopinid) {
    return request({
        url: '/deleteZanComic',
        method: 'post',
        data: {
            zuopinid,
        },
        loading: false,
    })
}

/**
 * @description 获取作品信息
 */
export function getMyzuopin(params) {
    return request({
        url: '/getMyzuopin',
        method: 'post',
        params,
        loading: false,
    })
}

/**
 * @description 新增作品
 * @param {*} data
 */
export function inserZuopin(data) {
    return request({
        url: '/inserZuopin',
        method: 'post',
        data,
        loading: true,
    })
}

/**
 * @description 更新作品
 * @param {*} data
 */
export function updateByIdZuopin(data) {
    return request({
        url: '/updateByIdZuopin',
        method: 'post',
        data,
        loading: true,
    })
}

/**
 * @description 删除作品
 * @param {*} zuopinid
 */
export function deleteZuopinByid(zuopinid) {
    return request({
        url: '/deleteZuopinByid',
        method: 'post',
        data: {
            zuopinid,
        },
        loading: true,
    })
}

/**
 * @description 新增分册
 * @param {*} zuopinId 作品id
 * @param {*} fencename 分册名称
 * @param {*} zhuti 主题
 */
export function addFenceByid(zuopinId, fenceName, zhuti) {
    return request({
        url: '/addImg',
        method: 'post',
        data: {
            zuopinId,
            fenceName,
            zhuti,
        },
        loading: true,
    })
}

/**
 * @description 删除分册
 * @param {*} zuopinid 作品id
 * @param {*} fencename 分册id
 */
export function deleteFenceByid(zuopinid, id) {
    return request({
        url: '/deleteFenceByid',
        method: 'post',
        data: {
            zuopinid,
            id,
        },
        loading: true,
    })
}

/**
 * @description 上傳作品图片
 * @param {*} data // zuopinId、fenceName、zhuti、file
 */
export function uploadImg(data) {
    return request({
        url: '/uploadImg',
        method: 'post',
        headers: { 'content-type': 'multipart/form-data' },
        data,
        loading: false,
    })
}

/**
 * @description: 检查文件上傳
 * @param {*} key 文件id
 * @return {*}
 */
export function check(key) {
    return request({
        url: '/check',
        method: 'post',
        data: {
            key,
        },
        loading: false,
    })
}

/**
 * @description 分片上傳作品
 * @param {*} data
 * zuopinId
 * fenceName
 * zhuti
 * file	分片文件
 * suffix	文件件的后缀名
 * shardIndex	当前正在上傳第几片
 * shardSize	当前片数大小
 * shardTotal	一共有多少片
 * size	文件大小
 * key	文件id
 */
export function upload(data) {
    return request({
        url: '/upload',
        method: 'post',
        headers: { 'content-type': 'multipart/form-data' },
        data,
        loading: false,
    })
}

/**
 * @description 获取书架
 */
export function getMyShujia() {
    return request({
        url: '/getMyShujia',
        method: 'post',
        loading: false,
    })
}

/**
 * @description 取消加入书架
 * @param {*} zuopinid 作品id
 */
export function deleteShujia(zuopinid) {
    return request({
        url: '/deleteShujia',
        method: 'post',
        data: {
            zuopinid,
        },
        loading: true,
    })
}

/**
 * @description 投诉
 * @param {*} zuopinid 作品id
 * @param {*} leixing 投诉类型id
 */
export function addTousu(zuopinid, leixing) {
    return request({
        url: '/addTousu',
        method: 'post',
        data: {
            zuopinid,
            leixing,
        },
        loading: true,
    })
}

/**
 * @description 发布作品
 * @param {*} id 作品id
 * @param {*} status 发布状态 0未发布 1发布
 */
export function publishWork(id, status) {
    return request({
        url: '/publishWork',
        method: 'post',
        data: {
            id,
            status,
        },
        loading: true,
    })
}

/**
 * @description 获取cloudflare临时令牌
 */
export function getImgToken() {
    // return request({
    //     url: window.global.batchTokenApi,
    //     method: 'get',
    //     headers: { Authorization: `Bearer 8trqeb7Sc8W7SvX9DD-NJFJ32zNtVFg7K-2GapCx` },
    //     loading: true,
    // })
    return request({
        url: '/getImgToken',
        method: 'post',
        loading: true,
    })
}

/**
 * @description 上傳图片
 * @param {*} token cloudflare临时令牌
 * @param {*} formData 图片 File
 */
export function uploadImgce(token, formData, onUploadProgress) {
    return request({
        url: window.global.s3ImageApi,
        method: 'post',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
        data: formData,
        onUploadProgress,
        loading: false,
    })
}

/**
 * @description 上傳分册图片（废弃）
 * @param {*} fenceId 分册id
 * @param {*} file 图片 File[]数组
 */
// export function uploadImgce(fenceId, file) {
//     return request({
//         url: '/uploadImgce',
//         method: 'post',
//         headers: { 'content-type': 'multipart/form-data' },
//         data: {
//             fenceId,
//             file,
//         },
//         loading: true,
//     })
// }

/**
 * @description 更新分册图片
 * @param {*} id 分册id
 * @param {*} imgurl 图片id列表
 */
export function updateFascicleByid(id, imgurl) {
    return request({
        url: '/updateFascicleByid',
        method: 'post',
        data: {
            id,
            imgurl,
        },
        loading: true,
    })
}

/**
 * @description 删除图片
 * @param {*} imgid 图片id
 */
export function deleteImg(imgid) {
    return request({
        url: '/updateFascicleByid',
        method: 'post',
        data: {
            imgid,
        },
        loading: true,
    })
}
