<!--
 * @Author: goldeneyes.li
 * @Date: 2022-12-03 00:04:31
 * @LastEditTime: 2024-11-13 15:34:17
 * @LastEditors: goldeneyes.li
 * @Description: 排行榜漫画列表
-->
<template>
    <section class="rank-book-list">
        <el-row v-if="bookList.length">
            <el-col class="rank">
                <el-carousel height="340px" :interval="6000" type="card" @change="handleChange">
                    <el-carousel-item v-for="(book, idx) in bookList.slice(0, 3)" :key="idx" @click="read(book)">
                        <div class="thumb">
                            <div class="icon-rank" :rank="idx + 1">{{ idx + 1 }}</div>
                            <el-image
                                :src="
                                    book.cover
                                        ? `${s3URL}${book.cover}/w=200,h=300`
                                        : book.imgurl
                                        ? `${s3URL}${book.imgurl}/w=200,h=300`
                                        : '/static/images/nothumb.jpg'
                                "
                            />
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div :key="currentRank" class="detail">
                    <h3 class="name">{{ `No.${currentIdx} ${currentRank.name}` }}</h3>
                    <div class="type">
                        {{ currentRank.fenlei }} /
                        <i class="ri-earth-line"></i>
                        <span>{{ currentRank.yuyan }}</span>
                        <el-tag v-if="currentRank.status == '0'" style="margin-left: 5px" type="danger">
                            {{ $t('已下架') }}
                        </el-tag>
                    </div>
                    <div class="tags">
                        <el-tag
                            v-for="(tag, idx) in currentRank.guanfangtag?.split(',').filter((item) => item != '')"
                            :key="idx"
                        >
                            {{ tag }}
                        </el-tag>
                    </div>
                    <div class="count">
                        <el-button
                            class="like"
                            :class="{ ifzan: currentRank.ifzan }"
                            link
                            :loading="liking"
                            @click="like(currentRank)"
                        >
                            <Icon :icon="currentRank.ifzan ? 'ri-thumb-up-fill' : 'ri-thumb-up-line'" />
                            ({{ currentRank.zan }})
                        </el-button>
                        <div class="view ms-3" link>
                            <Icon icon="ri-eye-2-line" />
                            ({{ currentRank.view || 0 }})
                        </div>
                    </div>
                    <div class="desc">
                        {{ currentRank.miaoshu }}
                        <el-button size="large" type="danger" @click="read(currentRank)">
                            <i class="ri-book-open-line"></i>
                            <span>{{ $t('開始閱讀') }}</span>
                        </el-button>
                    </div>
                </div>
            </el-col>
            <BookList class="d-none d-lg-block" :data="bookList.slice(4, 11)" />
        </el-row>
    </section>
</template>

<script setup name="RankBookList">
    import { ref } from 'vue'
    import router from '@/router'
    import { useUserStore } from '@/store/modules/user'
    import { addZanComic, deleteZanComic } from '@/api/book'
    import { enableVueFront } from '@/config'
    import { gp } from '@gp'

    const props = defineProps({
        data: {
            type: Object || Array,
            required: true,
        },
    })

    const s3URL = window.global.s3URL
    const { isAuthenticated } = useUserStore()
    const bookList = ref(props.data)

    // 切换显示排行
    const currentRank = ref(bookList.value[0])
    let currentIdx = ref(1)
    const handleChange = (idx) => {
        currentRank.value = bookList.value[idx]
        currentIdx.value = idx + 1
    }

    const liking = ref(false)
    // 点赞/取消点赞
    const like = async (book) => {
        if (!isAuthenticated) {
            gp.$baseMessage(gp.$t('請先登錄'), 'warning')
            return
        }
        try {
            liking.value = true
            if (book.ifzan) {
                await deleteZanComic(book.id)
                book.ifzan = false
                book.zan--
            } else {
                await addZanComic(book.id)
                book.ifzan = true
                book.zan++
            }
        } finally {
            liking.value = false
        }
    }

    // 跳转到漫画详情页
    const read = (item) => {
        if (enableVueFront) {
            const url = router.resolve({
                path: `/reader/${item.id}`,
            })
            window.open(url.href, '_blank')
        } else {
            window.open(`${window.location.origin}/workDetail?id=${item.id}`, '_blank')
        }
    }
</script>

<style lang="scss" scoped>
    .rank-book-list {
        width: 100%;
        :deep() {
            .el-row {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                .el-col.rank {
                    display: flex;
                    align-items: flex-start;
                    width: 100%;
                    margin-top: 20px;
                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                    .el-carousel {
                        flex: 0 0 540px;
                        width: 540px;
                        @include media-breakpoint-down(md) {
                            flex: 0 0 100%;
                            width: 100%;
                            height: 280px;
                            margin: 0 auto;
                        }
                        .el-carousel__mask,
                        .el-carousel__indicators,
                        .el-carousel__arrow {
                            display: none;
                            @include media-breakpoint-down(md) {
                                display: block;
                            }
                        }
                        .icon-rank {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            width: 36px;
                            height: 36px;
                            font-size: var(--font-size-lg);
                            font-weight: 700;
                            line-height: 36px;
                            color: #fafafa;
                            text-align: center;
                            border-radius: 4px 0 14px 0;
                            box-shadow: 0 2px 2px rgba(0, 0, 0, 10%);
                            &[rank='1'] {
                                background: linear-gradient(to bottom, #f9d71c 0%, #f58549 100%);
                            }
                            &[rank='2'] {
                                background: linear-gradient(to bottom, #c5c5c5 0%, #999 100%);
                            }
                            &[rank='3'] {
                                background: linear-gradient(to bottom, #cd7f32 0%, #9b5e2f 100%);
                            }
                        }
                        .el-image {
                            width: 100%;
                            max-width: 240px;
                            height: 320px;
                            border: 6px solid #fff;
                            box-shadow: var(--box-shadow-sm);
                            @include media-breakpoint-down(md) {
                                height: 240px;
                            }
                        }
                        .el-carousel__container {
                            padding-bottom: 30px;
                        }
                        .el-carousel__item.is-active {
                            .el-image {
                                box-shadow: var(--box-shadow-lg);
                            }
                        }
                    }
                    .detail {
                        margin-top: 0;
                        padding: 0 20px;
                        .type {
                            padding-top: 5px;
                            font-size: var(--font-size-sm);
                            color: var(--text-normal);
                        }
                        .tags {
                            display: none;
                            margin: 5px 0;
                            .el-tag {
                                margin: 3px 3px 0 0;
                            }
                        }
                        .count {
                            display: flex;
                            align-items: center;
                            .like {
                                display: flex;
                                align-items: center;
                                padding: 8px 0;
                                font-size: var(--font-size-sm);
                                color: var(--text-normal);
                                .icon {
                                    margin-right: 3px;
                                }
                                &.ifzan {
                                    .icon {
                                        color: $danger;
                                    }
                                }
                            }
                            .view {
                                font-size: var(--font-size-sm);
                                color: var(--text-normal);
                            }
                        }
                        .desc {
                            font-size: var(--font-size-md);
                            .el-button {
                                display: block;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
