
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-color-like\"><path d=\"M385.4 342.1a201 201 0 1 0 402 0 201 201 0 1 0-402 0Z\" fill=\"#FFCE00\" /><path d=\"M266.9 876.2H139.8c-24.9 0-45-20.1-45-45V400.1c0-24.9 20.1-45 45-45h127.1c24.9 0 45 20.1 45 45v431.1c0 24.8-20.1 45-45 45zm-82.1-90h37.1V445.1h-37.1v341.1zm599.1 96.7H385.4c-24.9 0-45-20.1-45-45V400.1c0-10.6 3.7-20.3 9.8-28l92.3-137.3c38.6-57.5 116.9-72.9 174.4-34.2 27.9 18.7 46.8 47.2 53.2 80.1 6.4 32.7-.2 66-18.6 93.7l-1 1.6 180.4-11.1c28.2-1.8 55.2 9.1 74.2 29.9 19.1 20.9 27.4 48.9 23 76.8l-52.8 333.3c-3.4 21.7-14.5 41.5-31.3 55.8-16.8 14.3-38.1 22.2-60.1 22.2zm-353.5-90H784c1.2 0 2.2-.9 2.4-2l52.8-333.3c.1-.4.2-1.1-.6-2-.8-.9-1.6-.8-1.9-.8h-.2l-268.3 16.5c-16.8 1-32.9-7.5-41.5-22-8.6-14.5-8.3-32.7.7-47l48.3-76.5c.2-.4.5-.7.7-1.1 5.3-7.9 7.2-17.4 5.4-26.8-1.8-9.3-7.2-17.4-15.1-22.7-16.3-11-38.5-6.6-49.5 9.7l-85.3 127c-.1.4-.2.8-.4 1.2-.3 1-.7 2-1 3v376.8z\" fill=\"#242F44\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}