/*
 * @Author: goldeneyes.li
 * @Date: 2022-11-05 01:12:04
 * @LastEditTime: 2023-02-01 18:00:23
 * @LastEditors: goldeneyes.li
 * @Description: 数据
 */
import request from '@/utils/request'
/**
 * @description 查询广告图
 * @params {*} name 广告图类型：
 * 1.	首页广告：guanggaoshouye 
 * 2.	漫画详细页面广告：guanggaoxiangxi
 * 3.	漫画在线浏览页面广告：guanggaoliulan	
 * 4.	左侧展示区域:cartoonarea
 * 5.	顶部大图片：bigslides
 * 6.	顶部小图片：smallslides

 */
export function getAdvertisementList(name) {
    return request({
        url: '/getAdvertisementList',
        method: 'post',
        params: {
            name,
        },
        loading: false,
    })
}

export const banners = [
    {
        name: '漫畫花園',
        desc: '現在加入，海量漫畫免費看',
        thumb: 'images/banner01.jpg',
        link: '',
    },
    {
        name: '立即註冊會員',
        desc: '現在加入，海量漫畫免費看',
        thumb: 'images/banner02.jpg',
        link: '',
    },
    {
        name: 'Comic Garden',
        desc: '現在加入，海量漫畫免費看',
        thumb: 'images/banner03.jpg',
        link: '',
    },
    {
        name: '',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_tatecomihalloween_l_02.png?10280000',
        link: '',
    },
    {
        name: '',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_denimoshojo_l_02.png?10280000',
        link: '',
    },
    {
        name: '',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_44706_l_02.png',
        link: '',
    },
    {
        name: '',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_44812_l_02.png?10240000',
        link: '',
    },
]

export const data = {
    newArrive: [
        {
            thumb: 'https://mhfm3tel.cdndm5.com/82/81521/20221005114904_450x600_88.jpg',
            name: '被追放的转生重骑士用游戏知识开无双',
            author: '猫子 武六甲理衣',
            like: {
                value: 4.5,
                count: 431,
            },
            desc: '「一無是處……身為劍聖的兒子、居然還會抽到籤王！」 15歲的加護儀式上，擁有劍聖血統的埃爾瑪，卻得到了典型的廢物職業-重騎士。 被剝奪了下任當家的寶座，還被逐出門戶。 因為重騎士極端的能力值和派不上用場的技能',
        },
        {
            thumb: 'https://mhfm4tel.cdndm5.com/66/65746/20210107131757_450x600_120.jpg',
            name: '如果作为冠军的我成为了公主的小白脸',
            author: '杠宪太 蓝藤唯',
            like: {
                value: 5,
                count: 1031,
            },
            desc: '如果作为冠军的我成为了公主的小白脸',
        },
        {
            thumb: 'https://mhfm3tel.cdndm5.com/46/45381/20181116233331_180x240_23.jpg',
            name: '异世界叔叔',
            author: '殆ど死んでいる',
            like: {
                value: 5,
                count: 231,
            },
            desc: '异世界叔叔漫画 ，遭遇车祸，穿越到异世界，在沉睡了17年后终于醒来的叔叔，他最关心的问题居然是“世嘉赢了吗”？！穿梭于现实和异世界回忆的新感觉现实幻想搞笑漫画！',
        },
        {
            thumb: 'https://mhfm9tel.cdndm5.com/44/43567/20180729092036_180x240_28.jpg',
            name: '妖精的尾巴 百年任务',
            author: '真岛浩上田敦夫',
            like: {
                value: 4,
                count: 310,
            },
            desc: '从本应完结的545话再次出发，延续下去的全新故事！',
        },
        {
            thumb: 'https://mhfm2tel.cdndm5.com/23/22629/20180116111630_450x600_100.jpg',
            name: '炎拳',
            author: '藤本タツキ',
            like: {
                value: 5,
                count: 310,
            },
            desc: '炎拳漫画 ，就因为一个被称为的祝福者 世界被冰雪与饥饿还有疯狂所笼罩。 对主人公阿格尼所降下的祝福，究竟是希望呢还是绝望 ——哥哥，我们来造孩子吧！',
        },
        {
            thumb: 'https://mhfm6tel.cdndm5.com/79/78304/20220530212745_450x600_89.jpg',
            name: '没有辣妹会对阿宅温柔!?',
            author: 'のりしろちゃん 鱼住鱼',
            like: {
                value: 5,
                count: 310,
            },
            desc: '不太敢宣扬自己喜欢“幼女向动画”的阿宅 濑尾卓也。有一天和班里的辣妹天音同学和伊地知同学有了瓜葛，好像天音同学也是阿宅…？阿宅邂逅辣妹的"喜欢"交错的校园种姓恋爱喜剧！！”',
        },
        {
            thumb: 'https://mhfm5tel.cdndm5.com/48/47061/20200628234751_360x480_66.jpg',
            name: '想要成为影之实力者',
            author: '逢沢 大介坂野杏梨',
            like: {
                value: 4,
                count: 310,
            },
            desc: '男高中生柏小太郎自称『硬汉的道路上不需要恋爱』，然而',
        },
    ],
    rank: [
        {
            thumb: 'https://mhfm9tel.cdndm5.com/22/21224/20210619093229_180x240_24.jpg',
            name: '月光下的异世界之旅',
            author: 'あずみ圭',
            like: {
                value: 4.5,
                count: 431,
            },
            desc: '月光下的异世界之旅漫画 ，因父母签订的契约不得不到异世界走一遭的男主，不幸地遇到了bitch女神，被毫不留情地抛弃在荒野之中，这是个开挂男主泡各种非人妹纸的故事',
        },
        {
            thumb: 'https://mhfm3tel.cdndm5.com/42/41093/20190730162935_180x240_18.jpg',
            name: '圣者无双',
            author: '秋風緋色ブロッコリーライオン',
            like: {
                value: 5,
                count: 1031,
            },
            desc: '命运之神打赌输给了异世界之神，将十个平凡的灵魂送到了异世界。十个灵魂中有一个与死亡命运抗争的上班族。这个化为抖M僵尸治愈士的男人，今后将会有怎么样的境遇——第4回网络小说大赛金奖，终于漫画化！',
        },
        {
            thumb: 'https://mhfm3tel.cdndm5.com/46/45381/20181116233331_180x240_23.jpg',
            name: '异世界叔叔',
            author: '殆ど死んでいる',
            like: {
                value: 5,
                count: 231,
            },
            desc: '异世界叔叔漫画 ，遭遇车祸，穿越到异世界，在沉睡了17年后终于醒来的叔叔，他最关心的问题居然是“世嘉赢了吗”？！穿梭于现实和异世界回忆的新感觉现实幻想搞笑漫画！',
        },
        {
            thumb: 'https://mhfm9tel.cdndm5.com/44/43567/20180729092036_180x240_28.jpg',
            name: '妖精的尾巴 百年任务',
            author: '真岛浩上田敦夫',
            like: {
                value: 4,
                count: 310,
            },
            desc: '从本应完结的545话再次出发，延续下去的全新故事！',
        },
        {
            thumb: 'https://mhfm5tel.cdndm5.com/51/50721/20190920114355_360x480_72.jpg',
            name: 'BLUE LOCK',
            author: '金城宗幸野村优介',
            like: {
                value: 5,
                count: 310,
            },
            desc: '在那瞬间，最热血的足球场上，突然云集',
        },
        {
            thumb: 'https://mhfm9tel.cdndm5.com/1/432/20190719155618_360x480_73.jpeg',
            name: '海贼王',
            author: '尾田荣一郎',
            like: {
                value: 5,
                count: 310,
            },
            desc: '相传22年前，在一个童话般的世界里，曾经拥有一切的海贼王在走上断头台的时候对人们狂笑道：“想要我的财宝是吗，想要就给你们！去找吧，全世界的宝藏都在那里！”',
        },
        {
            thumb: 'https://mhfm5tel.cdndm5.com/48/47061/20200628234751_360x480_66.jpg',
            name: '想要成为影之实力者',
            author: '逢沢 大介坂野杏梨',
            like: {
                value: 4,
                count: 310,
            },
            desc: '男高中生柏小太郎自称『硬汉的道路上不需要恋爱』，然而',
        },
        {
            thumb: 'https://mhfm5tel.cdndm5.com/19/18417/20190904142520_360x480_60.jpg',
            name: '辉夜大小姐想让我告白 ~天才们的恋爱头脑战~',
            author: '赤坂アカ',
            like: {
                value: 4,
                count: 310,
            },
            desc: '恋爱正是适合天才的游戏！？秀知院学园的学生会长（天才）和副会长（天才）每天都为了让对方向自己告白而绞尽脑汁',
        },
        {
            thumb: 'https://mhfm2tel.cdndm5.com/79/78526/20220609104808_450x600_118.jpg',
            name: '反派大小姐遇到的攻略对象各个都太有问题了',
            author: '稻井田壮 宛',
            like: {
                value: 4,
                count: 310,
            },
            desc: '十歲生日那天突然想起前世記憶的反派大小姐米斯蒂婭為了避免破滅結局努力想要與女主的攻略對像保持距離，然而事與願違，攻略對像不知為何都對她抱有異常的執著？',
        },
        {
            thumb: 'https://mhfm3tel.cdndm5.com/32/31328/20190305111521_450x600_115.jpg',
            name: '约定的梦幻岛',
            author: '白井カイウ 出水ぽすか',
            like: {
                value: 4,
                count: 310,
            },
            desc: '约定的梦幻岛漫画 ，妈妈说外面的世界好可怕，我不信； 但是那一天、我深深地体会到了妈妈说的是真的！ 因为不仅外面的世界、就连妈妈也好可怕…',
        },
    ],
}

export const ads = [
    {
        name: 'DeNIMO★人気作品キャンペーン',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_45075_l_02.png?10250000',
        link: '',
    },
    {
        name: 'comicブースト新刊フェア',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_44812_l_02.png?10240000',
        link: '',
    },
    {
        name: 'DeNIMO少女漫画特集',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_denimoshojo_l_02.png?10280000',
        link: '',
    },
    {
        name: 'サンデー新刊フェア！',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_44209_l_02.png',
        link: '',
    },
    {
        name: 'DeNIMO★人気作品キャンペーン',
        thumb: 'https://img.papy.co.jp/lc/renta/img/label/tp_45075_l_02.png?10250000',
        link: '',
    },
]
