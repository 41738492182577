<!--
 * @Author: goldeneyes.li
 * @Date: 2023-02-09 23:39:03
 * @LastEditTime: 2023-02-09 23:55:15
 * @LastEditors: goldeneyes.li
 * @Description: 骨架屏加载效果
-->
<template>
    <el-skeleton animated>
        <template #template>
            <el-skeleton-item style="max-width: 200px; height: 240px" variant="image" />
            <div style="padding: 14px">
                <el-skeleton-item style="width: 50%" variant="p" />
                <div style="display: flex; align-items: center; justify-items: space-between">
                    <el-skeleton-item style="margin-right: 16px" variant="text" />
                    <el-skeleton-item style="width: 30%" variant="text" />
                </div>
            </div>
        </template>
    </el-skeleton>
</template>

<script setup name="Skeleton"></script>
