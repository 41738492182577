/*
 * @Author: goldeneyes
 * @Date: 2021-10-31 14:12:12
 * @LastEditTime: 2022-11-01 16:13:46
 * @lastEditors: goldeneyes
 * @Description: 日期转换工具
 */
import { isEmpty } from '@/utils/validate'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (!isEmpty(time)) {
        let d
        if (dayjs.isDayjs(time)) {
            d = time
        } else if (typeof time == 'string') {
            //兼容ios
            time = time.replace(/-/g, '/')
            d = dayjs(time)
        } else {
            d = dayjs(time)
        }
        const now = dayjs()

        if (d.year() <= 1900) {
            return ''
        } else if (option) {
            return d.format(option)
        } else {
            const diff = now.diff(d) / 1000
            if (diff < 60) {
                return '刚刚'
            } else if (diff < 3600) {
                return now.diff(d, 'minute') + '分钟前'
            } else if (diff < 3600 * 24) {
                return now.diff(d, 'hour') + '小时前'
            } else if (diff < 3600 * 24 * 2) {
                return '1天前'
            } else if (d.year() != now.year()) {
                return d.format('YYYY-MM-DD HH:mm')
            } else {
                return d.format('MM-DD(dd) HH:mm')
            }
        }
    } else {
        return ''
    }
}

/**
 * @description 格式化时间（完整日期）
 * @param time
 * @returns {string}
 */
export function formatFullTime(time) {
    return formatTime(time, 'YYYY-MM-DD HH:mm:ss')
}

/**
 * @description 格式化时间（年月日）
 * @param time
 * @returns {string}
 */
export function formatShortTime(time) {
    return formatTime(time, 'YYYY-MM-DD')
}

/**
 * @description 获取日期之间所有日期数据
 * @param {} start
 * @param {*} end
 * @returns
 */
export function getBetweenDates(start, end) {
    let days = []

    let startDay = dayjs(start)
    let endDay = dayjs(end)

    if (endDay > startDay) {
        days.push(startDay.format('YYYY-MM-DD'))
        while (!startDay.isSame(endDay, 'day')) {
            startDay = startDay.add(1, 'day')
            days.push(startDay.format('YYYY-MM-DD'))
        }
    } else {
        days.push(endDay.format('YYYY-MM-DD'))
        while (!endDay.isSame(startDay, 'day')) {
            endDay = endDay.add(1, 'day')
            days.push(endDay.format('YYYY-MM-DD'))
        }
    }

    return days
}

/**
 * @description 计算日期之间的天数
 * @param {} start
 * @param {*} end
 * @returns
 */
export function getBetweenDays(start, end) {
    let startDay = dayjs(start)
    let endDay = dayjs(end)
    return Math.abs(endDay.diff(startDay, 'day'))
}

/**
 * @description 获取这周的周一日期
 * @param {} date
 * @returns
 */
export function getFirstDayOfWeek(date) {
    let d = dayjs(date)

    //星期几 0(星期日)到6(星期六)
    const week = d.day()

    //周日
    if (week == 0) {
        return d.subtract(6, 'day').format('YYYY-MM-DD')
    } else {
        return d.subtract(week - 1, 'day').format('YYYY-MM-DD')
    }
}
