/*
 * @Author: goldeneyes
 * @Date: 2021-10-29 17:43:54
 * @LastEditTime: 2023-01-31 11:17:06
 * @lastEditors: goldeneyes
 * @Description: vite配置
 */

// 开发以及部署时的URL
const publicPath = './'
// 生产环境构建文件的目录名
const outputDir = 'comicGarden'
// 放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
const assetsDir = 'assets'
// 开发环境每次保存时是否输出eslint编译警告
const lintOnSave = true
// 开发环境端口号
const devPort = 3001
// npm run build时是否自动生成7z压缩包
const build7z = false
// npm run build时是否生成gzip
const buildGzip = false

export { publicPath, outputDir, assetsDir, lintOnSave, devPort, build7z, buildGzip }
