/*
 * @Author: goldeneyes.li
 * @Date: 2022-10-31 15:54:46
 * @LastEditTime: 2023-02-23 15:37:11
 * @LastEditors: goldeneyes.li
 * @Description: 全局方法
 */
import { loadingText, messageDuration } from '@/config'
import { ElLoading, ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import { useI18n } from 'vue-i18n'

export let gp
export function setupGP(app) {
    /**
     * @description 全局翻译方法
     * @param {*} text
     */
    app.config.globalProperties.$t = (text) => {
        const { t, te } = useI18n({ useScope: 'global' })
        if (te(`${text}`)) return t(`${text}`)
        return text
    }

    /**
     * @description 全局加载层
     * @param {*} index
     * @param {*} text
     */
    app.config.globalProperties.$baseLoading = (index = null, text = loadingText) => {
        return ElLoading.service({
            lock: true,
            text: text,
            customClass: index ? `loading-type${index}` : `loading-type1`,
            background: 'rgba(0,0,0,.7)',
        })
    }

    /**
     * @description 全局Message
     * @param {*} message
     * @param {*} type
     * @param {*} dangerouslyUseHTMLString
     * @param {*} customClass
     */
    app.config.globalProperties.$baseMessage = (message, type, dangerouslyUseHTMLString, customClass) => {
        ElMessage({
            showClose: true,
            message,
            type,
            dangerouslyUseHTMLString,
            duration: messageDuration,
            customClass,
        })
    }

    /**
     * @description 全局Alert
     * @param {*} content
     * @param {*} title
     * @param {function} callback
     */
    app.config.globalProperties.$baseAlert = (content, title, dangerouslyUseHTMLString, callback) => {
        ElMessageBox.alert(content, title || '提示', {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString,
            callback: () => {
                if (callback) {
                    callback()
                }
            },
        }).then(() => {})
    }

    /**
     * @description 全局Confirm
     * @param {*} content
     * @param {*} title
     * @param {*} callback1
     * @param {*} callback2
     * @param {*} confirmButtonText
     * @param {*} cancelButtonText
     */
    app.config.globalProperties.$baseConfirm = (
        content,
        useHTML = false,
        callback1,
        callback2,
        title = '提示',
        confirmButtonText = '确定',
        cancelButtonText = '取消',
        center = false,
        distinguishCancelAndClose = false,
        draggable = true
    ) => {
        ElMessageBox.confirm(content, title, {
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            dangerouslyUseHTMLString: useHTML,
            closeOnClickModal: false,
            lockScroll: true,
            center: center,
            distinguishCancelAndClose,
            draggable,
        })
            .then(() => {
                if (callback1) {
                    callback1()
                }
            })
            .catch((action) => {
                if (callback2) {
                    // distinguishCancelAndClose设置为true，将区分关闭和取消两种操作,action的值为别为‘cancel’和‘close’，false时都为‘cancel’
                    callback2(action)
                }
            })
    }

    /**
     * @description 全局Prompt
     * @param {*} content
     * @param {*} title
     * @param {*} callback1
     * @param {*} callback2
     * @param {*} confirmButtonText
     * @param {*} cancelButtonText
     */
    app.config.globalProperties.$basePrompt = (
        content,
        useHTML = false,
        callback1,
        callback2,
        title = '提示',
        confirmButtonText = '确定',
        cancelButtonText = '取消',
        distinguishCancelAndClose = false,
        draggable = true
    ) => {
        ElMessageBox.prompt(content, title, {
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            dangerouslyUseHTMLString: useHTML,
            closeOnClickModal: false,
            type: 'warning',
            lockScroll: false,
            distinguishCancelAndClose,
            draggable,
        })
            .then((value) => {
                if (callback1) {
                    callback1(value)
                }
            })
            .catch((action) => {
                if (callback2) {
                    // distinguishCancelAndClose设置为true，将区分关闭和取消两种操作,action的值为别为‘cancel’和‘close’，false时都为‘cancel’
                    callback2(action)
                }
            })
    }

    /**
     * @description 全局Notification
     * @param {*} message
     * @param {*} title
     * @param {*} type
     * @param {*} position
     */
    app.config.globalProperties.$baseNotify = (message, title, type = 'success', position = 'top-right') => {
        ElNotification({
            title: title,
            message: message,
            position: position,
            type: type,
            duration: messageDuration,
        })
    }

    /**
     * @description 全局静态资源引用
     * @param {string} url 静态资源文件路径及文件名
     */
    app.config.globalProperties.$getAssetsFile = (url) => {
        return new URL(`../assets/${url}`, import.meta.url).href
    }

    gp = app.config.globalProperties
}
