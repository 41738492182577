/*
 * @Author: goldeneyes
 * @Date: 2021-10-29 17:43:54
 * @LastEditTime: 2023-02-17 00:56:50
 * @lastEditors: goldeneyes
 * @Description: 网络配置
 */

// 接口地址，开发环境为/mock-server
const baseURL = '/api'
// 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
const contentType = 'multipart/form-data'
// 操作正常code，支持String、Array、int多种类型
const successCode = [200, 0, 1, '200', '0', '1']
// 接口返回数据状态的字段名称
const statusName = 'code'
// 接口返回数据状态信息的字段名称
const messageName = 'message'

export { baseURL, contentType, successCode, statusName, messageName }
