/*
 * @Author: goldeneyes
 * @Date: 2021-04-29 17:43:54
 * @LastEditTime: 2024-03-28 16:30:25
 * @lastEditors: goldeneyes
 * @Description: 用户接口
 */
import request from '@/utils/request'

/**
 * @description 登录
 * @param {*} data {name mima yanzhengma}
 */
export function login(data) {
    return request({
        url: '/denglu',
        method: 'post',
        data,
        loading: false,
    })
}

/**
 * @description 获取验证码
 */
export function getVerificationCode() {
    return request({
        url: `/image`,
        method: 'post',
        responseType: 'blob',
        loading: false,
    })
}

/**
 * @description 注册用户
 * @param {*} data {email name mima nicheng yanzhengma}
 */
export function register(data) {
    return request({
        url: '/inserYongHu',
        method: 'post',
        data,
        loading: true,
    })
}

/**
 * @description 更新用户信息
 * @param {*} data {nicheng mima xinmima}
 */
export function updateInfo(data) {
    return request({
        url: '/updateInfo',
        method: 'post',
        data,
        loading: true,
    })
}

/**
 * @description 添加到历史
 * @param {*} workId 作品id
 */
export function addHistory(workId) {
    return request({
        url: '/addHistory',
        method: 'post',
        data: {
            workId,
        },
        loading: true,
        noneedLogout: false, // 401不需要跳登录
    })
}

/**
 * @description 获取历史
 */
export function getMyHistory(params) {
    return request({
        url: '/getMyHistory',
        method: 'post',
        params,
        loading: true,
    })
}

/**
 * @description 查询收藏分类
 */
export function selectSavetype(worktype) {
    return request({
        url: '/selectSavetype',
        method: 'post',
        params: {
            worktype,
        },
        loading: true,
    })
}

/**
 * @description 新建收藏分类
 * @param typename 分类名
 */
export function addSavetype(typename, worktype) {
    return request({
        url: '/addSavetype',
        method: 'post',
        data: {
            typename,
            worktype,
        },
        loading: true,
    })
}

/**
 * @description 删除收藏分类
 * @param typeid 分类id
 */
export function deleteSavetype(typeid) {
    return request({
        url: '/deleteSavetype',
        method: 'post',
        data: {
            typeid,
        },
        loading: true,
    })
}

/**
 * @description 加入收藏
 * @param zuopinid 作品id
 * @param typeid 分类id
 */
export function addShujia(zuopinid, typeid) {
    return request({
        url: '/addShujia',
        method: 'post',
        data: {
            zuopinid,
            typeid,
        },
        loading: true,
    })
}

/**
 * @description 删除收藏
 * @param zuopinid 作品id
 */
export function deleteShujia(zuopinid) {
    return request({
        url: '/deleteShujia',
        method: 'post',
        data: {
            zuopinid,
        },
        loading: true,
    })
}

/**
 * @description 打开收藏
 * @param typeid 收藏id
 */
export function getMyShujia(typeid) {
    return request({
        url: '/getMyShujia',
        method: 'post',
        data: {
            typeid,
        },
        loading: true,
    })
}

/**
 * @description 查询用户通知
 */
export function selectUserNews() {
    return request({
        url: '/selectUserNews',
        method: 'post',
        loading: false,
    })
}

/**
 * @description 查询系统通知
 */
export function selectAdminNews() {
    return request({
        url: '/selectAdminNews',
        method: 'post',
        loading: false,
    })
}

/**
 * @description: 发送邮件验证码
 * @param {*} email
 * @return {*}
 */
export function sendEmali(email) {
    return request({
        url: '/sendEmali',
        method: 'post',
        data: {
            email,
        },
        loading: true,
    })
}

/**
 * @description: 验证邮件验证码
 * @param {*} emailCode
 * @return {*}
 */
export function verifyEmali(emailCode) {
    return request({
        url: '/verifyEmali',
        method: 'post',
        data: {
            emailCode,
        },
        loading: true,
    })
}
