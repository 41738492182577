<!--
 * @Author: goldeneyes.li
 * @Date: 2022-12-11 02:14:17
 * @LastEditTime: 2024-12-10 11:31:54
 * @LastEditors: goldeneyes.li
 * @Description: 作品图片编辑组件
-->
<template>
    <el-dialog
        v-model="dialogVisible"
        align-center
        append-to-body
        class="images-editor-dialog"
        destroy-on-close
        draggable
        width="90%"
        @closed="handleClosed"
    >
        <template #title>
            <h4>{{ `编辑分册${fenceData?.fencename}：${fenceData?.zhuti}` }}</h4>
            <span>
                <Icon icon="ri-drag-drop-line" />
                <span>拖拽图片可排序</span>
            </span>
        </template>
        <div ref="listRef" class="list">
            <div v-for="(img, idx) in pageData" :key="img" :class="['item', { ready: img.status === 'ready' }]">
                <el-button circle class="btn-remove" :disabled="loading" type="danger" @click="removeImg(img)">
                    <Icon icon="ri-close-line" />
                </el-button>
                <el-image fit="cover" :src="img.url" @click="preview(imgList, img, idx)" />
                <span>
                    p{{ getImageIdx(img) + 1 }}
                    <el-tag v-if="img.status === 'ready'" type="warning">待上傳</el-tag>
                    <Icon v-if="img.status === 'success'" class="text-success" icon="ri-checkbox-circle-fill" />
                    <el-tooltip
                        v-if="img.status === 'error'"
                        :content="$t('上傳失敗，可以點擊上傳按鈕重新上傳')"
                        placement="top"
                    >
                        <Icon v-if="img.status === 'error'" class="text-danger" icon="ri-information-fill" />
                    </el-tooltip>
                    <el-progress
                        v-if="(loading && img.status === 'ready') || img.status === 'error'"
                        :percentage="img.process"
                    />
                </span>
            </div>
        </div>
        <el-pagination
            v-model:current-page="currentPage"
            background
            hide-on-single-page
            layout="prev, pager, next"
            :page-size="pageSize"
            :page-sizes="[50, 100]"
            :total="total"
        />
        <div class="upload-btn">
            <el-button class="select-btn" plain round size="large" type="primary">
                <el-upload
                    ref="uploader"
                    accept=".jpeg,.png,.jpg,.bmp,.gif"
                    action="#"
                    :auto-upload="false"
                    class="add"
                    :limit="200"
                    multiple
                    :on-change="handleOnChange"
                    :show-file-list="false"
                >
                    <template #trigger>
                        <Icon icon="ri-image-add-line" />
                        <span>{{ $t('新增圖片') }}</span>
                    </template>
                </el-upload>
            </el-button>
            <el-button
                v-if="uploadFiles.length"
                :loading="loading"
                round
                size="large"
                type="success"
                @click="handleUpload"
            >
                <Icon icon="ri-upload-cloud-2-line" />
                <span>{{ $t('上傳') }} ({{ uploadFiles.length }})</span>
            </el-button>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="handleClose()">{{ $t('關閉') }}</el-button>
                <el-button v-if="needUpdate" type="primary" @click="handleSave()">{{ $t('保存') }}</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup name="ImagesEditor">
    import { ref, computed, nextTick } from 'vue'
    import Sortable from 'sortablejs'
    import { api as viewerApi } from 'v-viewer'
    import { getImgToken, uploadImgce, updateFascicleByid, deleteImg } from '@/api/book'
    import { cloneDeep } from '@/utils/convert'
    import { isEmpty } from '@/utils/validate'
    import { gp } from '@gp'

    const emits = defineEmits(['update'])

    const dialogVisible = ref(false)
    const s3URL = window.global.s3URL
    const fenceData = ref()
    const uploader = ref()
    const loading = ref(false)
    const needUpdate = ref(false)

    // 图片列表
    const imgList = ref([])

    // 是否有待上傳文件
    const uploadFiles = computed(() => {
        return imgList.value.filter((item) => item.status === 'ready' || item.status === 'error')
    })

    // 翻页
    const currentPage = ref(1)
    const pageSize = ref(50)
    const total = computed(() => imgList.value.length)
    const pageData = computed(() => {
        return imgList.value.slice((currentPage.value - 1) * pageSize.value, currentPage.value * pageSize.value)
    })

    const getImageIdx = (img) => {
        return imgList.value.findIndex((item) => item === img)
    }

    /**
     * 预览
     */
    const preview = (images, img, index) => {
        if (img.status) {
            return
        }
        let _images = cloneDeep(images)
        _images = _images.filter((item) => item.status !== 'ready' && item.status !== 'error')
        _images.forEach((item, index) => {
            _images[index] = s3URL + item.name + '/public'
        })
        viewerApi({
            options: {
                toolbar: true,
                initialViewIndex: index || 0,
                zIndex: 9999,
            },
            images: _images,
        })
    }

    /**
     * 移除图片
     */
    const removeImg = async (img) => {
        const index = imgList.value.indexOf(img)
        if (index > -1) {
            imgList.value.splice(index, 1)
            needUpdate.value = true
        }
    }

    /**
     * 检查文件类型
     */
    const checkFileType = (file) => {
        file = file.raw
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg'
        const isPNG = file.type === 'image/png'
        const isBMP = file.type === 'image/bmp'
        const isGIF = file.type === 'image/gif'
        const isLt2M = file.size / 1024 / 1024 < 20

        if (!isJPG && !isPNG && !isBMP && !isGIF) {
            gp.$baseMessage(gp.$t('只能上傳jpg、png、bmp、gif格式的图片'))
            return false
        }
        if (!isLt2M) {
            gp.$baseMessage(gp.$t('图片大小不能超过20MB'))
            return false
        }
        return true
    }

    /**
     * @description: 添加上傳文件
     * @param {*} file
     * @return {*}
     */
    const handleOnChange = (file) => {
        if (checkFileType(file)) {
            imgList.value.push({
                name: '待上傳',
                uid: file.uid,
                status: 'ready',
                process: 0,
                file: file,
                url: URL.createObjectURL(file.raw),
            })
        }
    }

    /**
     * @description: 关闭弹窗
     * @return {*}
     */
    const handleClose = () => {
        if (uploadFiles.value.length > 0) {
            gp.$baseConfirm(
                gp.$t(`有${uploadFiles.value.length}张待上傳的圖片未上傳，確定要关闭嗎？`),
                false,
                async () => {
                    dialogVisible.value = false
                }
            )
        } else {
            dialogVisible.value = false
        }
    }

    /**
     * @description: 文件上傳
     * @return {*}
     */
    const handleUpload = async () => {
        if (isEmpty(uploadFiles.value)) {
            gp.$baseMessage(gp.$t('請先選擇圖片'))
            return
        }
        try {
            loading.value = true
            // 先获取临时令牌
            const { msg: token } = await getImgToken()
            let uploadCount = uploadFiles.value.length

            uploadFiles.value.forEach(async (file) => {
                let formData = new FormData()
                formData.append('file', file.file.raw)
                formData.append('metadata', JSON.stringify({ uid: file.file.uid }))
                // 上傳进度
                const onUploadProgress = (progressEvent) => {
                    file.process = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                }
                try {
                    file.status = 'ready'
                    const data = await uploadImgce(token, formData, onUploadProgress)
                    file.status = 'success'
                    file.name = data.result.id
                } catch (error) {
                    file.status = 'error'
                    file.process = 0
                } finally {
                    uploadCount--
                    if (uploadCount === 0) {
                        loading.value = false
                        needUpdate.value = true
                        gp.$baseMessage(gp.$t('上傳圖片成功，請點擊保存按鈕關閉窗口'), 'success')
                    }
                }
            })
        } finally {
            loading.value = false
        }
    }

    /**
     * @description: 保存
     * @return {*}
     */
    const handleSave = async () => {
        imgList.value = imgList.value.filter((item) => item.status !== 'ready' && item.status !== 'error')
        let imgurl = imgList.value.map((item) => item.name).join(',')
        if (uploadFiles.value.length > 0) {
            gp.$baseConfirm(
                gp.$t(`有${uploadFiles.value.length}张待上傳的圖片未上傳，確定要关闭嗎？`),
                false,
                async () => {
                    try {
                        await updateFascicleByid(fenceData.value.id, imgurl)
                        gp.$baseMessage('保存成功', 'success')
                        dialogVisible.value = false
                        emits('update')
                    } catch (error) {
                        gp.$baseMessage('保存失敗，請重試', 'success')
                    }
                }
            )
        } else {
            try {
                await updateFascicleByid(fenceData.value.id, imgurl)
                gp.$baseMessage('保存成功', 'success')
                dialogVisible.value = false
                emits('update')
            } catch (error) {
                gp.$baseMessage('保存失敗，請重試', 'success')
            }
        }
    }

    /**
     * 打开弹窗
     */
    // 拖拽排序
    const listRef = ref(null)
    const sortableInstance = ref(null)

    const show = (data) => {
        fenceData.value = cloneDeep(data)
        imgList.value = []
        needUpdate.value = false
        loading.value = false
        let imgUrl = fenceData.value.imgurl ? fenceData.value.imgurl.split(',') : []
        imgUrl.forEach((item) => {
            imgList.value.push({
                name: item,
                url: `${s3URL}${item}/w=100,h=120`,
            })
        })
        dialogVisible.value = true
        nextTick(() => {
            sortableInstance.value = new Sortable(listRef.value, {
                animation: 150,
                onEnd: (evt) => {
                    const [removed] = imgList.value.splice(evt.oldIndex, 1)
                    imgList.value.splice(evt.newIndex, 0, removed)
                    needUpdate.value = true
                },
            })
        })
    }

    const handleClosed = () => {
        if (sortableInstance.value) {
            sortableInstance.value.destroy()
        }
    }

    defineExpose({
        show,
    })
</script>

<style lang="scss">
    .images-editor-dialog {
        .el-dialog__body {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 200px);
            overflow: hidden;
            .list {
                display: flex;
                align-content: flex-start;
                flex-wrap: wrap;
                flex: 1;
                overflow: auto;
                .item {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    padding: 8px;
                    width: 100px;
                    flex: 0 0 100px;
                    cursor: pointer;
                    .btn-remove {
                        display: none;
                        position: absolute;
                        top: 10px;
                        right: 0;
                        padding: 0;
                        z-index: 1;
                        span {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .el-image {
                        width: 100%;
                        height: 120px;
                        border: 4px solid #fff;
                        box-shadow: var(--box-shadow-sm);
                        border-radius: 6px;
                    }
                    > span {
                        padding: 6px;
                        text-align: center;
                        font-size: 11px;
                        .el-tag {
                            transform: scale(0.8);
                        }
                    }
                    .add {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 120px;
                        font-size: var(--font-size);
                        color: var(--primary);
                        cursor: pointer;
                        border: 1px dashed var(--primary);
                        border-radius: 4px;
                        .el-upload--text {
                            display: flex;
                            flex-direction: column;
                        }
                        .icon {
                            font-size: var(--font-size-ex);
                        }
                    }
                    &:hover {
                        .btn-remove {
                            display: block;
                        }
                    }
                }
            }
            .upload-btn {
                text-align: center;
                .el-upload {
                    width: 100%;
                    display: block;
                }
                .el-button {
                    width: 200px;
                    > span {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
</style>
