<!--
 * @Author: goldeneyes.li
 * @Date: 2022-02-28 11:22:04
 * @LastEditors: goldeneyes.li
 * @LastEditTime: 2022-12-31 16:13:05
 * @Description: 公告
-->
<template>
    <div class="announcement">
        <div class="notice-box" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
            <transition name="notice-slide">
                <p :key="noticeList.id" class="notice-item">
                    <Icon icon="ri-volume-down-line" />
                    {{ noticeList.text }}
                </p>
            </transition>
        </div>
    </div>
</template>

<script setup name="Announcement">
    import { ref, onMounted, computed } from 'vue'

    const props = defineProps({})

    const state = ref({
        noticeArr: [{ title: '漫畫花園，現在加入，海量漫畫免費看' }, { title: '上傳你的作品，让更多人认识您！' }],
        number: 0,
    })

    const noticeList = computed(() => {
        return {
            id: state.value.number,
            text: state.value.noticeArr[state.value.number].title,
        }
    })

    // 滚动函数
    const scrollMove = () => {
        state.value.timer = setTimeout(() => {
            if (state.value.number === state.value.noticeArr.length - 1) {
                state.value.number = 0
            } else {
                state.value.number += 1
            }
            scrollMove()
        }, 3000)
    }
    // 鼠标进入
    const mouseEnter = () => {
        clearInterval(state.value.timer)
    }
    // 鼠标离开
    const mouseLeave = () => {
        scrollMove()
    }

    onMounted(() => {
        scrollMove()
    })
</script>

<style lang="scss" scoped>
    .announcement {
        :deep() {
            .notice-box {
                width: 100%;
                height: 32px;
                position: relative;
                overflow: hidden;
                @include nowrap;
            }
            .notice-item {
                width: 100%;
                height: 32px;
                line-height: 32px;
                font-size: var(--font-size-md);
                position: absolute;
                top: 0;
                .icon {
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    background: var(--linear-gradient-pink);
                    border-radius: 50%;
                }
            }
            .notice-slide-enter-active,
            .notice-slide-leave-active {
                transition: all 0.3s linear;
            }
            .notice-slide-enter {
                top: 32px;
            }
            .notice-slide-leave-to {
                opacity: 0;
            }
        }
    }
</style>
