<!--
 * @Author: goldeneyes.li
 * @Date: 2022-12-08 22:33:38
 * @LastEditTime: 2023-05-30 15:06:39
 * @LastEditors: goldeneyes.li
 * @Description: 筛选
-->
<template>
    <div class="filter">
        <div class="filter-container">
            <div class="list">
                <dl v-if="type === 'sp'">
                    <el-checkbox-group v-model="state.lang.value">
                        <el-checkbox v-for="lang in state.lang.options" :key="lang.id" :label="lang.id">
                            {{ $t(lang.name) }}
                        </el-checkbox>
                    </el-checkbox-group>
                </dl>
                <dl>
                    <el-checkbox-group v-model="state.officalTags.value">
                        <el-checkbox v-for="tag in state.officalTags.options" :key="tag.name" :label="tag.name">
                            {{ $t(tag.name) }}
                        </el-checkbox>
                    </el-checkbox-group>
                </dl>
                <div class="d-flex justify-content-center">
                    <el-button size="large" type="success" @click="handleFilter">
                        <span>{{ $t('搜索') }}</span>
                    </el-button>
                    <el-button v-show="state.isFilter" plain size="large" type="info" @click="handleClearFilter">
                        <i class="ri-filter-off-line"></i>
                        <span>{{ $t('清除') }}</span>
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup name="Filter">
    import { reactive, onMounted, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import language from 'jian_fan'
    import { getTagList } from '@/api/tag'
    import { getGovernmentAdultTagList } from '@/api/adult'
    import { gp } from '@gp'
    import router from '@/router'

    const props = defineProps({
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        // 分类id
        id: {
            type: Number,
            default: 0,
        },
        // 类型
        type: {
            type: String,
            default: 'default',
        },
    })

    const emit = defineEmits(['change'])
    const state = reactive({
        lang: {
            value: [],
            options: [
                {
                    name: '中文',
                    id: '1',
                },
                {
                    name: '日文',
                    id: '2',
                },
                {
                    name: 'English',
                    id: '3',
                },
            ],
        },
        officalTags: {
            value: [],
            options: [],
        },
        userTags: {
            value: [],
            options: [],
        },
        isFilter: computed(() => {
            return state.lang.value.length || state.officalTags.value.length || state.userTags.value.length
        }),
    })

    const route = useRoute()
    // 从路由参数获取筛选条件
    if (route.query.lang) {
        route.query.lang = language.traditional(route.query.lang)
        state.lang.value = route.query?.lang.split(',')
    }
    if (route.query.officalTags) {
        route.query.officalTags = language.traditional(route.query.officalTags)
        state.officalTags.value = route.query?.officalTags.split(',').filter((item) => item != '')
    }
    if (route.query.userTags) {
        route.query.userTags = language.traditional(route.query.userTags)
        state.userTags.value = route.query?.userTags.split(',').filter((item) => item != '')
    }

    const getTagData = async () => {
        state.officalTags.options = []
        if (props.type === 'sp') {
            const { data } = await getGovernmentAdultTagList()
            state.officalTags.options = data
        } else {
            const { data } = await getTagList()
            state.officalTags.options = data
        }
    }

    const handleFilter = () => {
        if (
            state.lang.value.length === 0 &&
            state.officalTags.value.length === 0 &&
            state.userTags.value.length === 0
        ) {
            // gp.$baseMessage(gp.$t('請選擇篩選條件'), 'error')
            if (props.type === 'sp') {
                router.push({
                    name: 'SpCategory',
                    params: {
                        id: '1qaz',
                    },
                    query: {
                        page: 1,
                    },
                })
            } else {
                router.push({
                    name: 'Category',
                    params: {
                        id: '3434',
                    },
                    query: {
                        page: 1,
                    },
                })
            }
            return
        }
        emit('change', {
            lang: state.lang.value,
            officalTags: state.officalTags.value,
            userTags: state.userTags.value,
        })
    }

    const handleClearFilter = () => {
        state.lang.value = []
        state.officalTags.value = []
        state.userTags.value = []
        emit('change', {
            lang: state.lang.value,
            officalTags: state.officalTags.value,
            userTags: state.userTags.value,
        })
    }

    onMounted(() => {
        getTagData()
    })
</script>

<style lang="scss" scoped>
    .filter {
        position: relative;
        :deep() {
            .filter-container {
                display: flex;
                flex-direction: column;
                padding: 10px var(--base-padding);
                background: #fff;
                border-radius: 4px;
                box-shadow: var(--box-shadow-sm);
                h4 {
                    flex: 0;
                    margin: 10px 0 20px 0;
                    font-size: var(--font-size-lg);
                    i {
                        font-size: var(--font-size-lg);
                    }
                }
                .list {
                    flex: 1;
                    overflow: auto;
                    dl {
                        margin-bottom: 10px;
                        border-bottom: 1px solid var(--base-border-color);
                        dt {
                            font-weight: 700;
                        }
                        .el-checkbox {
                            margin: 5px 0;
                            .el-checkbox__inner {
                                width: 18px;
                                height: 18px;
                                border-radius: 4px;
                                &::after {
                                    left: 6px;
                                    height: 8px;
                                    border-width: 2px;
                                }
                            }
                        }
                        .el-checkbox-group {
                            display: flex;
                            flex-wrap: wrap;
                            .el-checkbox {
                                flex: 0 0 calc(100% / 3);
                                margin: 5px 0;
                            }
                        }
                        &:last-of-type {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
</style>
