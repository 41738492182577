import { createApp } from 'vue'
import App from './App.vue'
// 加载全局样式
import './styles/global.scss'
// 加载remix图标样式
import 'remixicon/fonts/remixicon.css'
// 全局方法
import { setupGP } from './utils/globalProperties'
// svg图标注册脚本(vite-plugin-svg-icons插件配置见vite.config.js)
import 'virtual:svg-icons-register'
import { setupStore } from '@/store' // 状态管理
import { setupRouter } from '@/router' // 路由
// import router from './router'
import i18n from '@/locales' // 多语种
import ElementPlus from 'element-plus'
import '@/styles/element-variables.scss'
import zhTw from 'element-plus/es/locale/lang/zh-tw'

import 'viewerjs/dist/viewer.css' // 阅读器样式
import VueViewer from 'v-viewer'

// 谷歌统计
// import { createGtm } from '@gtm-support/vue-gtm'

const app = createApp(App)
setupGP(app)
setupStore(app)
setupRouter(app)
app.use(i18n)
app.use(VueViewer)
// app.use(
//     createGtm({
//         id: 'G-Y677PZBQMQ',
//         vueRouter: router,
//     })
// )

// 加载自定义组件
const Components = import.meta.globEager('./components/**/*.vue')
for (const key in Components) {
    if (Object.prototype.hasOwnProperty.call(Components, key)) {
        app.component(Components[key].default.name, Components[key].default)
    }
}

app.use(ElementPlus, {
    size: 'small',
    i18n: i18n.global.t,
    locale: zhTw,
})

app.mount('#app')
