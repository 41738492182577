<!--
 * @Author: goldeneyes.li
 * @Date: 2022-02-28 11:22:04
 * @LastEditors: goldeneyes.li
 * @LastEditTime: 2022-12-28 22:10:48
 * @Description: 多语种切换
-->
<template>
    <el-dropdown class="lang" style="display: none" @command="changeLang">
        <span class="el-dropdown-link">
            <i class="ri-earth-fill"></i>
            <span>{{ getCurrLangLabel(locale) }}</span>
        </span>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item command="zh-TW">繁体中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script setup name="Lang">
    import { useI18n } from 'vue-i18n'
    import { getCurrLangLabel } from '@/locales'

    const props = defineProps({})

    // 多语种设置
    const { locale } = useI18n({ useScope: 'global' })
    function changeLang(lang) {
        localStorage.setItem('language', lang)
        locale.value = lang
    }
</script>

<style lang="scss" scoped></style>
