/*
 * @Author: goldeneyes.li
 * @Date: 2022-10-31 16:12:30
 * @LastEditTime: 2024-12-02 11:40:45
 * @LastEditors: goldeneyes.li
 * @Description: 路由配置
 */
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { filterRoutes } from '@/utils/routes'
import { setupPermissions } from './permissions'
import { isHashRouterMode, publicPath, enableVueFront } from '@/config'

// 录入员路由
const manage = [
    {
        path: '/manage',
        name: 'Manage',
        redirect: '/manage/addWork',
        meta: {
            title: '管理',
            icon: 'ri-user-settings-line',
        },
        children: [
            {
                path: 'addWork',
                name: 'AddWork',
                component: () => import('@/views/user-center/Work.vue'),
                meta: {
                    title: '新增作品',
                    icon: 'ri-file-user-fill',
                },
            },
            {
                path: 'complaint/:id',
                name: 'Complaint',
                component: () => import('@/views/reader/index.vue'),
                meta: {
                    title: '投訴',
                    icon: 'ri-message-3-line',
                },
            },
            {
                path: 'review/:id',
                name: 'Review',
                component: () => import('@/views/reader/index.vue'),
                meta: {
                    title: '審核',
                    icon: 'ri-history-fill',
                },
            },
        ],
    },
    // 作品页
    {
        path: '/reader/:id',
        name: 'Reader',
        meta: {
            title: '閱讀',
        },
        component: () => import('@/views/reader/index.vue'),
    },
    // 漫画阅读
    {
        path: '/reader/:id/:volume',
        name: 'Detail',
        meta: {
            title: '詳細',
        },
        component: () => import('@/views/reader/detail/index.vue'),
    },
    // 403页
    {
        path: '/403',
        name: '403',
        meta: {
            title: '403',
        },
        component: () => import('@/views/403.vue'),
    },
    // 404页
    {
        path: '/404',
        name: '404',
        meta: {
            title: '404',
        },
        component: () => import('@/views/404.vue'),
    },
    // 未匹配任何路由跳转首页
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
        name: 'NotFound',
    },
]

// 普通用户路由
const user = [
    {
        path: '/manage',
        name: 'Manage',
        redirect: '/manage/addWork',
        meta: {
            title: '管理',
            icon: 'ri-user-settings-line',
        },
        children: [
            {
                path: 'addWork',
                name: 'AddWork',
                component: () => import('@/views/user-center/Work.vue'),
                meta: {
                    title: '新增作品',
                    icon: 'ri-file-user-fill',
                },
            },
            {
                path: 'complaint/:id',
                name: 'Complaint',
                component: () => import('@/views/reader/index.vue'),
                meta: {
                    title: '投訴',
                    icon: 'ri-message-3-line',
                },
            },
            {
                path: 'review/:id',
                name: 'Review',
                component: () => import('@/views/reader/index.vue'),
                meta: {
                    title: '審核',
                    icon: 'ri-history-fill',
                },
            },
        ],
    },
    {
        path: '/',
        redirect: '/index',
    },
    // 登录页
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登錄',
        },
        component: () => import('@/views/auth/login/index.vue'),
    },
    // 注册页
    {
        path: '/register',
        name: 'Register',
        meta: {
            title: '新用戶註冊',
        },
        component: () => import('@/views/auth/register/index.vue'),
    },
    // 漫画阅读
    {
        path: '/reader/:id/:volume',
        name: 'Detail',
        meta: {
            title: '詳細',
        },
        component: () => import('@/views/reader/detail/index.vue'),
    },
    // 个人中心
    {
        path: '/user-center',
        name: 'UserCenter',
        component: () => import('@/views/user-center/index.vue'),
        redirect: '/user-center/user-info',
        meta: {
            title: '用戶中心',
            icon: 'ri-file-user-fill',
        },
        children: [
            {
                path: 'user-info',
                name: 'UserInfo',
                component: () => import('@/views/user-center/UserInfo.vue'),
                meta: {
                    title: '用戶信息',
                    icon: 'ri-file-user-fill',
                },
            },
            // {
            //     path: 'message',
            //     name: 'Message',
            //     component: () => import('@/views/user-center/Message.vue'),
            //     meta: {
            //         title: '我的消息',
            //         icon: 'ri-message-3-line',
            //     },
            // },
            {
                path: 'history',
                name: 'History',
                component: () => import('@/views/user-center/History.vue'),
                meta: {
                    title: '觀看歷史',
                    icon: 'ri-history-fill',
                },
            },
            {
                path: 'work',
                name: 'Work',
                component: () => import('@/views/user-center/Work.vue'),
                meta: {
                    title: '我的上傳',
                    icon: 'ri-gallery-upload-fill',
                },
                props: (route) => ({
                    type: route.query.type,
                    key: route.query.type,
                }),
            },
            {
                path: 'collect',
                name: 'Collect',
                component: () => import('@/views/user-center/Collect.vue'),
                meta: {
                    title: '我的收藏',
                    icon: 'ri-bookmark-3-fill',
                },
            },
        ],
    },
    // 作者主页
    {
        path: '/user/:id/home',
        name: 'UserHome',
        meta: {
            title: '作者主頁',
        },
        component: () => import('@/views/user-home/index.vue'),
    },
    // 403页
    {
        path: '/403',
        name: '403',
        meta: {
            title: '403',
        },
        component: () => import('@/views/403.vue'),
    },
    // 404页
    {
        path: '/404',
        name: '404',
        meta: {
            title: '404',
        },
        component: () => import('@/views/404.vue'),
    },
    // 未匹配任何路由跳转首页
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
        name: 'NotFound',
    },
]

// 普通用户路由开启vue前台页面
if (enableVueFront) {
    user.push(
        // 框架页
        {
            path: '/index',
            name: 'Index',
            component: () => import('@/views/index/index.vue'),
            redirect: '/index/home',
            children: [
                // 首页
                {
                    path: 'home',
                    name: 'Home',
                    component: () => import('@/views/home/index.vue'),
                    meta: {
                        title: '首頁',
                    },
                },
                // 列表页
                {
                    path: 'category/:id',
                    name: 'Category',
                    component: () => import('@/views/category/index.vue'),
                    meta: {
                        title: '漫畫列表',
                    },
                    props: (route) => ({ page: route.query.page }),
                },
                // 检索页
                {
                    path: 'search',
                    name: 'Search',
                    component: () => import('@/views/search/index.vue'),
                    meta: {
                        title: '漫畫搜索',
                    },
                    props: true,
                },
            ],
        },
        // sp框架页
        {
            path: '/sp',
            name: 'Sp',
            component: () => import('@/views/sp/index.vue'),
            redirect: '/sp/home',
            children: [
                // 首页
                {
                    path: 'home',
                    name: 'SpHome',
                    component: () => import('@/views/sp/home/index.vue'),
                    meta: {
                        title: '成人',
                    },
                },
                // 列表页
                {
                    path: 'category/:id',
                    name: 'SpCategory',
                    component: () => import('@/views/sp/category/index.vue'),
                    meta: {
                        title: '漫畫列表',
                    },
                    props: (route) => ({ page: route.query.page }),
                },
                // 检索页
                {
                    path: 'spSearch',
                    name: 'SpSearch',
                    component: () => import('@/views/sp/search/index.vue'),
                    meta: {
                        title: '漫畫搜索',
                    },
                    props: true,
                },
            ],
        },
        // 作品页
        {
            path: '/reader/:id',
            name: 'Reader',
            meta: {
                title: '閱讀',
            },
            component: () => import('@/views/reader/index.vue'),
        }
    )
}

/**
 * @description 路由定义
 */
export const constantRoutes = window.global.siteType === 'manage' ? manage : user

export const finalRoutes = filterRoutes(constantRoutes, publicPath)

const router = createRouter({
    // 根据config配置判断是否使用hash模式
    history: isHashRouterMode ? createWebHashHistory(publicPath) : createWebHistory(publicPath),
    routes: constantRoutes,
})

export function setupRouter(app) {
    setupPermissions(router)
    app.use(router)
    return router
}

export default router
