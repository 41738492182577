<!--
 * @Author: goldeneyes.li
 * @Date: 2022-11-01 00:30:18
 * @LastEditTime: 2023-03-24 23:54:24
 * @LastEditors: goldeneyes.li
 * @Description: 全局检索组件
-->
<template>
    <div class="search">
        <el-form @keyup.enter="handleSearch">
            <el-input
                v-model="state.keywords"
                clearable
                :placeholder="$t(placeholder)"
                size="large"
                @blur="handleSearch"
                @clear="handleClearSearch()"
            >
                <template #prepend>
                    <el-select v-model="state.currentType" :placeholder="$t('選擇類型')">
                        <el-option
                            v-for="(type, idx) in state.types"
                            :key="type.id"
                            :label="$t(type.name)"
                            :value="type.id"
                        />
                    </el-select>
                </template>
                <template #append>
                    <i class="ri-search-2-line" @click="handleSearch"></i>
                </template>
            </el-input>
        </el-form>
    </div>
</template>

<script setup name="Search">
    import { onMounted, reactive, watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { getFenleiList } from '@/api/book'
    import { getTypeAdultList } from '@/api/adult'

    const route = useRoute()
    const router = useRouter()
    const props = defineProps({
        placeholder: {
            type: String,
            default: '搜索你喜歡的漫畫/作者',
        },
        type: {
            type: String,
            default: 'default',
        },
    })

    const state = reactive({
        keywords: route.query.keywords || '',
        currentType: route.query.id || 'all',
        types: [],
    })

    const getTypes = async () => {
        state.types = []
        if (props.type === 'sp') {
            const { data } = await getTypeAdultList()
            state.types = data
            state.types.unshift({ name: '全部', id: 'all' })
        } else {
            const { data } = await getFenleiList()
            state.types = data
            state.types.unshift({ name: '全部', id: 'all' })
        }
        for (let index = 0; index < state.types.length; index++) {
            const element = state.types[index]
            if (element.name === '首頁' || element.name === '首页') {
                state.types.splice(index, 1)
            }
        }
    }

    // 清除检索
    const handleClearSearch = () => {
        let pathName = ''
        if (router.currentRoute.value.path.startsWith('/sp')) {
            pathName = 'SpSearch'
        } else {
            pathName = 'Search'
        }
        router.push({
            name: pathName,
            query: {
                id: state.currentType,
            },
        })
    }

    // 检索
    const handleSearch = () => {
        let pathName = ''
        if (router.currentRoute.value.path.startsWith('/sp')) {
            pathName = 'SpSearch'
        } else {
            pathName = 'Search'
        }
        router.push({
            name: pathName,
            query: {
                id: state.currentType,
                keywords: state.keywords,
                lang: route.query?.lang,
                officalTags: route.query?.officalTags,
            },
        })
    }

    // 监听路由参数变化
    watch(
        () => route.query,
        (val) => {
            state.keywords = val.keywords || ''
            state.currentType = val.id || 'all'
        }
    )

    onMounted(() => {
        getTypes()
    })
</script>

<style lang="scss" scoped>
    .search {
        ::v-deep() {
            .el-input-group {
                height: 50px;
                background-color: #ffffff;
                border-radius: 4px;
                box-shadow: var(--box-shadow-md);
            }
            .el-input__wrapper {
                background: transparent;
                box-shadow: none;
            }
            .el-input-group__prepend,
            .el-input-group__append {
                background-color: transparent;
                box-shadow: none;
                .el-select .el-input {
                    &:hover,
                    &:active,
                    &.is-focus {
                        .el-input__wrapper {
                            box-shadow: none !important;
                        }
                    }
                    .el-input__wrapper {
                        box-shadow: none !important;
                    }
                }
                .el-select {
                    width: 180px;
                    .el-input__inner {
                        text-align: center !important;
                    }
                }
            }
            .el-input-group__append {
                font-size: var(--font-size-lg);
            }
        }
    }
</style>
