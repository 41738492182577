import axios from 'axios'
import { contentType, messageName, statusName, successCode, tokenName } from '@/config'
import qs from 'qs'
import router from '@/router'
import { isArray } from '@/utils/validate'
import { gp } from '@gp'
import { useUserStore } from '@/store/modules/user'

let loadingInstance

// 操作正常Code数组
const codeVerificationArray = isArray(successCode) ? [...successCode] : [...[successCode]]

// 当前正在请求的数量
let needLoadingRequestCount = 0

const CODE_MESSAGE = {
    200: '服务器成功返回请求数据',
    201: '新建或修改数据成功',
    202: '一个请求已经进入后台排队(异步任务)',
    204: '删除数据成功',
    400: '发出信息有误',
    401: '用户没有权限(令牌失效、用户名、密码错误、登录过期)',
    402: '前端无痛刷新token',
    403: '用户得到授权，但是访问是被禁止的',
    404: '访问资源不存在',
    406: '请求格式不可得',
    410: '请求资源被永久删除，且不会被看到',
    500: '服务器发生错误',
    502: '网关错误',
    503: '服务不可用，服务器暂时过载或维护',
    504: '网关超时',
}

const handleData = ({ config, data, status, statusText }) => {
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0 && loadingInstance) {
        loadingInstance.close()
    }
    // 若data.data.status存在，覆盖默认status
    let code = data && data[statusName] ? data[statusName] : status
    // 若code属于操作正常code，则status修改为200
    if (codeVerificationArray.indexOf(code) + 1) code = 200
    if (config.url.indexOf('/check') > -1) {
        code = 200
    }
    switch (code) {
        case 200:
            /**
             * 业务层级错误处理
             * 响应内容：
             * 正确内容：{ status: 大于0, data: { }, msg: '操作正常' }
             * 错误内容：{ status: 小于0, msg: '非法参数' }
             */
            return data
        case 401:
            // 清空用户信息，并跳转到登陆页面
            useUserStore().resetAll()
            if (!config.noneedLogout) {
                router.push({ path: '/login', replace: true }).then(() => {})
                return
            }
            return data
        case 402:
            // 刷新本地token
            return data
        case 403:
            router.push({ path: '/403' }).then(() => {})
            break
    }
    // 异常处理
    // 若data.msg存在，覆盖默认提醒消息
    const errMsg = `提示：${
        data && data[messageName] ? data[messageName] : CODE_MESSAGE[code] ? CODE_MESSAGE[code] : statusText
    }`
    if (code !== 401) {
        gp.$baseMessage(errMsg, 'error', true, 'lgset-hey-message-error')
    }
    const err = new Error(errMsg)

    return Promise.reject(err)
}

/**
 * axios初始化
 */
const instance = axios.create({
    baseURL: window.global.baseURL,
    timeout: window.global.requestTimeout,
    headers: {
        'Content-Type': contentType,
    },
})

/**
 * axios请求拦截器
 */
instance.interceptors.request.use(
    (config) => {
        const userStore = useUserStore()
        const token = userStore.isAuthenticated

        if (config.url.indexOf('/cloudflare') > -1 || config.url.indexOf('/imagedelivery') > -1) {
            config.baseURL = ''
        }

        // 根据config tokenName配置自定义headers
        if (token) {
            config.headers[tokenName] = token
        }
        // get请求添加时间戳避免缓存
        if (/get/i.test(config.method)) {
            config.params = config.params || {}
            config.params.t = '_' + new Date().getTime()
        }

        if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') {
            config.data = qs.stringify(config.data)
        }

        // config中loading参数为false时，不显示加载loading
        if (config.loading !== false && needLoadingRequestCount === 0) {
            if (config.msg) {
                loadingInstance = gp.$baseLoading(1, config.msg)
            } else {
                loadingInstance = gp.$baseLoading(1)
            }
        }

        needLoadingRequestCount++

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

/**
 * axios响应拦截器
 */
instance.interceptors.response.use(
    (response) => handleData(response),
    (error) => {
        const { response } = error
        if (response === undefined) {
            needLoadingRequestCount--
            if (needLoadingRequestCount === 0 && loadingInstance) {
                loadingInstance.close()
            }
            gp.$baseMessage('未知错误', 'error')
            const err = new Error('未知错误')
            return Promise.reject(err)
        } else return handleData(response)
    }
)

export default instance
