<!--
 * @Author: goldeneyes.li
 * @Date: 2023-04-24 17:54:55
 * @LastEditTime: 2024-07-04 00:55:27
 * @LastEditors: goldeneyes.li
 * @Description: 遊戲编辑/新增
-->
<template>
    <el-form
        ref="formRef"
        v-loading="loading"
        class="form"
        label-position="left"
        label-width="100px"
        :model="form"
        require-asterisk-position="right"
        :rules="rules"
        @keyup.enter="handleSubmit"
    >
        <el-form-item :label="$t('遊戲名稱')" prop="name">
            <el-input v-model.trim="form.name" tabindex="1" />
        </el-form-item>
        <el-form-item :label="$t('遊戲封面')" prop="cover">
            <el-upload
                ref="coverUploader"
                :auto-upload="false"
                class="cover-uploader"
                :disabled="isInspector"
                :on-change="beforeCoverUpload"
                :show-file-list="false"
            >
                <div v-if="!form.cover" class="cover">请上傳封面</div>
                <el-image
                    v-if="form.cover"
                    class="cover"
                    :src="form?.cover?.includes('blob:') ? `${form.cover}` : `${s3URL}${form.cover}/w=200,h=260` || ''"
                />
            </el-upload>
        </el-form-item>
        <el-form-item :label="$t('語言分類')" prop="category">
            <el-radio-group v-model="form.language.value">
                <el-radio v-for="(item, idx) in form.language.options" :key="idx" :label="item.id">
                    {{ item.name }}
                </el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('遊戲大小')" prop="size">
            <el-input-number v-model="form.size" controls-position="right" />
            &nbsp;&nbsp;MB
        </el-form-item>
        <div class="d-flex">
            <el-form-item :label="$t('種子鏈接')" prop="seed" style="flex: 2">
                <el-input v-model.trim="form.seed" tabindex="3">
                    <template #prefix>
                        <i class="ri-links-line"></i>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('種子解壓碼')" label-width="60" prop="seedcode">
                <el-input v-model.trim="form.seedcode" tabindex="4">
                    <template #prefix>
                        <i class="ri-folder-zip-line"></i>
                    </template>
                </el-input>
            </el-form-item>
        </div>
        <div class="d-flex">
            <el-form-item :label="$t('網盤鏈接')" prop="netdisk" style="flex: 2">
                <el-input v-model.trim="form.netdisk" tabindex="5">
                    <template #prefix>
                        <i class="ri-links-line"></i>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('網盤提取碼')" label-width="60" prop="netdiskout">
                <el-input v-model.trim="form.netdiskout" tabindex="6">
                    <template #prefix>
                        <i class="ri-inbox-unarchive-line"></i>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('網盤解壓碼')" label-width="60" prop="netcode">
                <el-input v-model.trim="form.netcode" tabindex="7">
                    <template #prefix>
                        <i class="ri-folder-zip-line"></i>
                    </template>
                </el-input>
            </el-form-item>
        </div>
        <el-form-item :label="$t('官方標簽')" prop="officialTags">
            <el-checkbox-group v-model="form.officialTags.value">
                <el-checkbox-button v-for="(item, idx) in form.officialTags.spOptions" :key="idx" :label="item.name">
                    {{ item.name }}
                </el-checkbox-button>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item class="user-tag" :label="$t('用戶標簽')" prop="userTags">
            <el-tag
                v-for="tag in form.userTags.value"
                :key="tag"
                closable
                type="warning"
                @close="handleRemoveUserTag(tag)"
            >
                {{ tag }}
            </el-tag>
            <el-select
                v-if="inputVisible"
                v-model="inputValue"
                allow-create
                filterable
                :placeholder="$t('請輸入標簽')"
                remote
                :remote-method="getUserTagOptions"
                @change="handleInputConfirm()"
            >
                <el-option v-for="item in form.userTagOptions" :key="item.name" :label="item.name" :value="item.name" />
            </el-select>
            <el-button v-else @click="showInput">+ {{ $t('添加標簽') }}</el-button>
        </el-form-item>
        <el-form-item :label="$t('描述')" prop="desc">
            <el-input v-model="form.desc" maxlength="2000" :rows="6" show-word-limit tabindex="3" type="textarea" />
        </el-form-item>
        <div style="display: flex; justify-content: center">
            <el-button :loading="saving" size="success" type="primary" @click="save">
                <i class="ri-save-2-fill"></i>
                {{ $t('保存') }}
            </el-button>
        </div>
    </el-form>
</template>

<script setup name="GameEditor">
    import { ref, reactive, computed, nextTick, onMounted } from 'vue'
    import { inserZuopin, updateByIdZuopin } from '@/api/book'
    import { getGovernmentAdultTagList, getUserAdultTagList } from '@/api/adult'
    import { isArray, isEmpty } from '@/utils/validate'
    import { gp } from '@gp'
    import { cloneDeep } from '@/utils/convert'

    const props = defineProps({
        value: {
            type: Object,
            default: () => ({}),
        },
        isInspector: {
            type: Boolean,
            default: false,
        },
        isNew: {
            type: Boolean,
            default: true,
        },
    })

    const emit = defineEmits(['save'])

    const bookData = computed(() => cloneDeep(props.value))
    const loading = ref(false)
    const saving = ref(false)
    const inputValue = ref('')
    const inputVisible = ref(false)
    const inputRef = ref()
    const formRef = ref(null)
    const s3URL = window.global.s3URL
    const form = reactive({
        name: '',
        cover: '',
        coverFile: null,
        category: {
            value: 'sws241',
        },
        language: {
            value: '1',
            options: [
                { id: '1', name: '中文' },
                { id: '2', name: '日文' },
                { id: '3', name: '英文' },
            ],
        },
        officialTags: {
            value: [],
            spOptions: [],
        },
        userTags: {
            value: [],
            spOptions: [],
        },
        userTagOptions: [],
        size: '',
        seed: '',
        seedcode: '',
        netdisk: '',
        netdiskout: '',
        netcode: '',
        desc: '',
    })

    const rules = {
        name: [{ required: true, trigger: 'change', message: `${gp.$t('遊戲名稱')} ${gp.$t('不能為空')}` }],
    }

    /**
     * 获取官方tag列表
     */
    const getOfficialTags = async () => {
        const requests = [getGovernmentAdultTagList()]
        const [spTagRes] = await Promise.all(requests)
        form.officialTags.spOptions = spTagRes.data
    }

    /**
     * 获取用户tag列表
     */
    const getUserTags = async () => {
        loading.value = true
        let requests = [getUserAdultTagList()]
        try {
            const [spRes] = await Promise.all(requests)
            form.userTags.spOptions = spRes.data
        } finally {
            loading.value = false
        }
    }

    /**
     * 获取用户tag options
     */
    const getUserTagOptions = (query) => {
        if (isEmpty(query)) {
            query = ''
        }
        form.userTagOptions = form.userTags.spOptions.filter((item) => {
            return item.name.toLowerCase().includes(query.toLowerCase())
        })
    }

    // 删除用户标签
    const handleRemoveUserTag = (tag) => {
        form.userTags.value.splice(form.userTags.value.indexOf(tag), 1)
    }

    // 显示用户标签输入
    const showInput = () => {
        inputVisible.value = true
        nextTick(() => {
            inputRef.value?.input?.focus()
        })
    }

    // 添加用户标签
    const handleInputConfirm = () => {
        if (inputValue.value) {
            // 判断是否重复
            if (form.userTags.value.find((i) => i === inputValue.value)) {
                gp.$message({
                    type: 'warning',
                    message: '標籤已存在',
                })
                inputVisible.value = false
                inputValue.value = ''
                return
            }
            form.userTags.value.push(inputValue.value)
        }
        inputVisible.value = false
        inputValue.value = ''
    }

    // 上傳封面检查
    const beforeCoverUpload = (rawFile) => {
        rawFile = rawFile.raw
        if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/gif') {
            gp.$baseMessage(gp.$t('只允許上傳jpg,png,gif格式！'), 'error')
            return false
        } else if (rawFile.size / 1024 / 1024 > 10) {
            gp.$baseMessage(gp.$t('上傳文件大小不能超過10Mb!'), 'error')
            return false
        }
        form.coverFile = rawFile
        form.cover = URL.createObjectURL(rawFile)
        return true
    }

    /**
     * 保存遊戲
     */
    const save = () => {
        if (props.isInspector) {
            // 确定弹窗
            gp.$baseConfirm(gp.$t('確定要修改遊戲信息嗎？'), false, () => {
                formRef.value.validate(async (valid) => {
                    if (valid) {
                        const data = {
                            name: form.name,
                            coverfile: form.coverFile,
                            worktype: 2,
                            fenlei: form.category.value,
                            yuyan: form.language.value,
                            guanfangtag: encodeURIComponent(form.officialTags.value.join(',')),
                            yonghutag: encodeURIComponent(form.userTags.value.join(',')),
                            miaoshu: form.desc,
                            size: form.size,
                            seed: form.seed,
                            seedcode: form.seedcode,
                            netdisk: form.netdisk,
                            netdiskout: form.netdiskout,
                            netcode: form.netcode,
                        }
                        saving.value = true
                        if (props.isNew) {
                            try {
                                await inserZuopin(data)
                            } finally {
                                saving.value = false
                                emit('save')
                            }
                        } else {
                            data.id = form.id
                            try {
                                await updateByIdZuopin(data)
                            } finally {
                                saving.value = false
                                emit('save')
                            }
                        }
                    }
                })
            })
        } else {
            formRef.value.validate(async (valid) => {
                if (valid) {
                    const data = {
                        name: form.name,
                        coverfile: form.coverFile,
                        worktype: 2,
                        fenlei: form.category.value,
                        yuyan: form.language.value,
                        guanfangtag: encodeURIComponent(form.officialTags.value.join(',')),
                        yonghutag: encodeURIComponent(form.userTags.value.join(',')),
                        miaoshu: form.desc,
                        size: form.size,
                        seed: form.seed,
                        seedcode: form.seedcode,
                        netdisk: form.netdisk,
                        netdiskout: form.netdiskout,
                        netcode: form.netcode,
                    }
                    saving.value = true
                    if (props.isNew) {
                        try {
                            await inserZuopin(data)
                        } finally {
                            saving.value = false
                            emit('save')
                        }
                    } else {
                        data.id = form.id
                        try {
                            await updateByIdZuopin(data)
                        } finally {
                            saving.value = false
                            emit('save')
                        }
                    }
                }
            })
        }
    }

    onMounted(async () => {
        loading.value = true
        try {
            const requests = [getOfficialTags(), getUserTags()]
            await Promise.all(requests)
            if (!props.isNew) {
                form.id = bookData.value.id
                form.name = bookData.value.name
                form.cover = bookData.value.cover
                form.coverFile = ''
                form.isSP = bookData.value.worktype == '2' || false
                form.category.value = 'sws241'
                form.language.value = form.language.options.find((i) => i.name === bookData.value.yuyan).id
                const officialTags = bookData.value.guanfangtag
                    ? isArray(bookData.value.guanfangtag)
                        ? bookData.value.guanfangtag
                        : bookData.value.guanfangtag.split(',').filter((item) => item != '')
                    : []
                form.officialTags.value = form.officialTags['spOptions']
                    .filter((i) => officialTags.includes(i.name))
                    .map((i) => i.name)
                form.userTags.value = bookData.value.yonghutag
                    ? isArray(bookData.value.yonghutag)
                        ? bookData.value.yonghutag
                        : bookData.value.yonghutag.split(',').filter((item) => item != '')
                    : []
                form.desc = bookData.value.miaoshu
                form.size = bookData.value.size
                form.seed = bookData.value.seed
                form.seedcode = bookData.value.seedcode
                form.netdisk = bookData.value.netdisk
                form.netdiskout = bookData.value.netdiskout
                form.netcode = bookData.value.netcode
            } else {
                form.name = ''
                form.cover = ''
                form.coverFile = ''
                form.isSP = true
                form.category.value = 'sws241'
                form.language.value = form.language.options[0].id
                form.officialTags.value = []
                form.userTags.value = []
                form.desc = ''
                form.size = ''
                form.seed = ''
                form.seedcode = ''
                form.netdisk = ''
                form.netdiskout = ''
                form.netcode = ''
            }
        } finally {
            loading.value = false
        }
    })
</script>

<style lang="scss" scoped>
    :deep() {
        .d-flex {
            .el-form-item {
                margin-right: 10px;
            }
        }
        .el-checkbox-button {
            margin: 4px;
            .el-checkbox-button__inner {
                border: 1px dashed var(--base-border-color);
                border-radius: 2px;
            }
            &.is-checked {
                .el-checkbox-button__inner {
                    border-color: transparent;
                }
            }
        }
        .cover-uploader .el-upload {
            position: relative;
            display: flex;
            flex-direction: column;
            .cover {
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--primary);
                border: 1px dashed var(--primary);
                border-radius: 4px;
                width: 100px;
                height: 120px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                transition: var(--el-transition-duration-fast);
            }
            .edit-cover {
                @include absoluteCenter;
                display: none;
            }
            &:hover {
                .edit-cover {
                    opacity: 1;
                }
            }
        }
        .user-tag {
            .el-tag {
                margin-right: 5px;
            }
            .el-input {
                width: 100px;
            }
        }
    }
</style>
