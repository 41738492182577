/*
 * @Author: goldeneyes
 * @Date: 2021-10-29 17:43:54
 * @LastEditTime: 2022-12-10 23:23:40
 * @lastEditors: goldeneyes
 * @Description: 多语种英文对照
 */
export default {
    漫畫花園: 'Comic Garden',
    首頁: 'Home',
    版權所有: 'All Rights Reserved',
    用戶登錄: 'User Sign in',
    登錄中: 'Sign In...',
    退出登錄: 'Sign Out',
    用戶名: 'User Name',
    昵稱: 'Nick Name',
    密碼: 'Password',
    新密碼: 'New Password',
    請輸入用戶名: 'Enter your username',
    輸入新密碼: 'Enter new password',
    再次輸入新密碼: 'Enter new password again',
    請輸入密碼: 'Enter password',
    請輸入驗證碼: 'Verification code',
    立即註冊會員: 'Register Now',
    創建賬號: 'Create Account',
    不能為空: 'Required',
    海量免費漫畫: 'Massive Free Comics',
    '已有賬號？立即登錄': 'Already have an account? Sign in now',
    '還未註冊？立即註冊': 'Not registered yet? Sign up now',
    '現在加入，海量漫畫免費看': 'Join now, read unlimited comics for free',
    '作品搜索：': 'Filter:',
    '搜索你喜歡的漫畫/作者': 'Search your favorite comics/authors',
    漫畫列表: 'Manga List',
    近期更新: 'Recently',
    同人誌: 'Fanart',
    CG畫冊: 'CG Album',
    單行本: 'Offprint',
    國漫: 'Chinese Comic',
    韓漫: 'Korean Comic',
    Cosplay: 'Cosplay',
    新用戶註冊: 'Sign Up',
    登錄: 'Sign In',
    個人中心: 'User Center',
    用戶信息: 'User Info',
    觀看歷史: 'History',
    我的書架: 'Bookshelf',
    我的上傳: 'My Works',
    我的收藏: 'My Collections',
    您有新的消息: 'You have new messages',
    '人氣排行榜(Top10)': 'Popular Ranking(Top10)',
    最近更新: 'Recent Updates',
    熱門連載: 'Popular Serials',
    開始閱讀: 'Read',
    题材: 'Genre',
    熱血: 'hot-blood',
    戀愛: 'love',
    搞笑: 'funny',
    校園: 'school',
    科幻: 'science-fiction',
    魔法: 'magic',
    冒險: 'adventure',
    競技: 'competition',
    治愈: 'healing',
    懸疑: 'suspense',
    戰爭: 'war',
    歷史: 'history',
    機戰: 'mecha',
    耽美: 'yaoi',
    少女: 'shoujo',
    少年: 'shounen',
    後宮: 'harem',
    萌系: 'cute',
    推理: 'detective',
    恐怖: 'horror',
    勵誌: 'inspiration',
    運動: 'sports',
    職場: 'office',
    穿越: 'time-travel',
    奇幻: 'fantasy',
    進度: 'Progress',
    連載中: 'Serializing',
    已完结: 'Completed',
    受眾: 'Audience',
    少兒: 'Children',
    青少年: 'Teenagers',
    成人: 'Adults',
    首字母: 'Initial',
    全部: 'All',
    用戶中心: 'User Center',
    添加分類: 'Add Category',
    添加標簽: 'Add Label',
}
