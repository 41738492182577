/*
 * @Author: goldeneyes.li
 * @Date: 2023-01-11 17:12:43
 * @LastEditTime: 2023-03-11 23:15:28
 * @LastEditors: goldeneyes.li
 * @Description: sp类型接口
 */
import request from '@/utils/request'

/**
 * @description 获得分类列表
 */
export function getTypeAdultList() {
    return request({
        url: '/getTypeAdultList',
        method: 'post',
        loading: true,
    })
}

/**
 * @description 获得sp官方tag
 */
export function getGovernmentAdultTagList() {
    return request({
        url: '/getGovernmentAdultTagList',
        method: 'post',
        loading: true,
    })
}

/**
 * @description 获得sp用户tag
 * @params {*} tagname
 */
export function getUserAdultTagList(tagname) {
    return request({
        url: '/getUserAdultTagList',
        method: 'post',
        params: {
            tagname,
        },
        loading: false,
    })
}
