<!--
 * @Author: goldeneyes.li
 * @Date: 2022-12-02 23:57:01
 * @LastEditTime: 2023-06-02 15:13:58
 * @LastEditors: goldeneyes.li
 * @Description: 广告列表
-->
<template>
    <div class="ads-list">
        <a v-for="(item, idx) in adsData" :key="idx" class="item" :href="item.url" target="_blank">
            <el-image :src="`${s3URL}${item.tupian}/w=300,h=200`" />
        </a>
    </div>
</template>

<script setup name="AdsList">
    import { ref, onMounted } from 'vue'
    import { getAdvertisementList } from '@/api/banners'

    const props = defineProps({
        type: {
            type: String,
            default: '',
        },
    })
    const adsData = ref([])
    const s3URL = window.global.s3URL

    /**
     * @description: 广告图
     * @return {*}
     */
    const getAds = async () => {
        let type = 'cartoonarea'
        if (props.type === 'sp') {
            type = 'crcartoonarea'
        }
        const { data } = await getAdvertisementList(type)
        adsData.value = data
    }

    onMounted(() => {
        getAds()
    })
</script>

<style lang="scss" scoped>
    .ads-list {
        padding: 10px;
        .item {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            text-align: center;
            .el-image {
                margin: 0 auto;
                margin-bottom: 5px;
                border: 4px solid transparent;
                border-radius: 4px;
            }
            &:hover {
                .el-image {
                    border: 4px solid #fff;
                    box-shadow: var(--box-shadow-lg);
                    transition: var(--transition-base);
                }
            }
        }
    }
</style>
