/*
 * @Author: Tom.zhang
 * @Date: 2022-11-08 15:32:27
 * @LastEditors: goldeneyes.li
 * @LastEditTime: 2024-06-21 19:17:02
 * @Description: 存储用户相关的store
 */

import { defineStore } from 'pinia'
import { login, register } from '@/api/user'
import { setToken, getToken, removeToken } from '@/utils/token'
import { tokenName } from '@/config'
import { gp } from '@gp'

const loginErrorMsg = `登录接口异常，未正确返回${tokenName}...`

export const useUserStore = defineStore('user', {
    state: () => ({
        token: getToken(),
        userInfo: localStorage.getItem('userInfo')
            ? JSON.parse(localStorage.getItem('userInfo'))
            : { touxiang: '', nicheng: '' },
    }),
    getters: {
        isAuthenticated: (state) => state.token,
        getUserInfo: (state) => state.userInfo,
    },
    actions: {
        /**
         * @description 设置用户信息
         * @param {*} userInfo 从接口获取的用户信息
         */
        setUser(userInfo) {
            this.userInfo = userInfo
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
        },
        /**
         * @description 设置token
         * @param {*} token 从接口获取的登录令牌
         */
        setToken(token) {
            this.token = token
            setToken(token)
        },

        /**
         * @description 登录
         * @param {*} loginData 登录数据
         * @return {*}
         */
        async login(loginData) {
            const { data } = await login(loginData)
            if (data && data[tokenName]) {
                this.setToken(data[tokenName])
                this.setUser(data)
            } else {
                gp.$baseMessage(loginErrorMsg, 'error')
                return Promise.reject()
            }
        },

        /**
         * @description 注册
         * @param {*} formData 注册数据
         * @return {*}
         */
        async register(formData) {
            await register(formData)
        },

        /**
         * @description 退出登录
         */
        async logout() {
            this.resetAll()
        },

        /**
         * @description 清除登录状态
         */
        resetAll() {
            this.setToken(null)
            removeToken()
        },
    },
})
