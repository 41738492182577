<!--
 * @Author: goldeneyes.li
 * @Date: 2022-10-31 16:15:52
 * @LastEditTime: 2024-12-11 22:12:43
 * @LastEditors: goldeneyes.li
 * @Description: 头部组件
-->
<template>
    <el-affix @change="posChange">
        <el-header ref="header" class="header dark">
            <div class="container-fluid">
                <!-- logo -->
                <div class="logo">
                    <a href="javascript: window.location.href='/'">
                        <h1>{{ $t(appName) }}</h1>
                    </a>
                </div>
                <!-- 导航/检索 -->
                <div class="nav d-none d-lg-block">
                    <el-menu :default-active="$route.fullPath" :ellipsis="false" mode="horizontal">
                        <template v-for="(menu, index) in catalog" :key="index">
                            <el-menu-item v-if="!menu.children" :index="menu.path" @click="handleClickMenu(menu)">
                                <i v-if="menu.icon" class="icon" :class="menu.icon"></i>
                                <span>{{ $t(menu.name) }}</span>
                            </el-menu-item>
                            <el-sub-menu v-else>
                                <template #title>
                                    <i v-if="menu.icon" class="icon" :class="menu.icon"></i>
                                    <span>{{ $t(menu.name) }}</span>
                                </template>
                                <el-menu-item
                                    v-for="(submenu, index) in menu.children"
                                    :key="index"
                                    :index="submenu.path"
                                >
                                    <i v-if="submenu.icon" class="icon" :class="submenu.icon"></i>
                                    <span>{{ $t(submenu.name) }}</span>
                                </el-menu-item>
                            </el-sub-menu>
                        </template>
                        <li v-if="type !== 'sp'" class="el-menu-item" @click="openSp"><span>成人</span></li>
                    </el-menu>
                    <Search :type="type" />
                </div>
                <!-- 子导航 -->
                <div class="subnav">
                    <el-menu :default-active="$route.path" :ellipsis="false" mode="horizontal" router>
                        <template v-for="(menu, index) in subnav" :key="index">
                            <el-menu-item v-if="!menu.children && menu.show" :index="menu.path">
                                <i class="icon" :class="menu.icon"></i>
                                <span>{{ $t(menu.name) }}</span>
                            </el-menu-item>
                            <el-sub-menu v-else-if="menu.children && menu.show">
                                <template #title>
                                    <i class="icon" :class="menu.icon"></i>
                                    <span>{{ $t(menu.name) }}</span>
                                </template>
                                <el-menu-item
                                    v-for="(submenu, index) in menu.children"
                                    :key="index"
                                    :index="submenu.path"
                                >
                                    <i class="icon" :class="submenu.icon"></i>
                                    <span>{{ $t(submenu.name) }}</span>
                                </el-menu-item>
                            </el-sub-menu>
                        </template>
                    </el-menu>
                    <Avatar v-if="!isEmpty(isLogin)" :type="type" />
                </div>
                <!-- 语种等 -->
                <div class="extnav" style="display: none">
                    <el-dropdown v-if="!isEmpty(isLogin)" class="message" popper-class="message">
                        <span class="el-dropdown-link">
                            <el-badge :value="2">
                                <i class="ri-notification-4-fill"></i>
                            </el-badge>
                        </span>
                        <template #dropdown>
                            <div class="message-box" style="padding: 10px">
                                <ul>
                                    <li>
                                        <router-link to="/user-center/message">
                                            {{ $t('您的作品《美好的一天》通過審核了') }}
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/user-center/message">
                                            {{ $t('歡迎註冊，快來上傳你的第一個作品吧！') }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </el-dropdown>
                    <Lang />
                </div>
            </div>
        </el-header>
    </el-affix>
</template>

<script setup name="Header">
    import { ref, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from '@/store/modules/user'
    import { getFenleiList } from '@/api/book'
    import { getTypeAdultList } from '@/api/adult'
    import { isEmpty } from '@/utils/validate'
    import { appName, enableVueFront } from '@/config'

    const router = useRouter()
    const header = ref(null)
    let isLogin = useUserStore().isAuthenticated
    const catalog = ref([])

    const props = defineProps({
        type: {
            type: String,
            default: 'default',
        },
    })

    /**
     * @description: 判断是否固定头部
     * @param {*} fixed
     * @return {*}
     */
    const posChange = (fixed) => {
        if (fixed) {
            header.value.$el.classList.add('fixed-header')
        } else {
            header.value.$el.classList.remove('fixed-header')
        }
    }

    /**
     * @description: 获取分类列表
     * @return {*}
     */
    const getCatalog = async () => {
        try {
            catalog.value = []
            // sp分类
            if (props.type === 'sp') {
                const sorts = {
                    首页: 1,
                    最新更新: 2,
                    單行本: 3,
                    同人誌: 4,
                    '雜誌&CG': 5,
                    Cosplay: 6,
                    韓漫: 7,
                }
                const { data } = await getTypeAdultList()
                const _array = data || []
                for (let index = 0; index < _array.length; index++) {
                    const element = _array[index]
                    if (element.id === 'w3fefg') {
                        continue
                    } else {
                        catalog.value.push({
                            name: element.name,
                            id: element.id,
                            path: `${window.location.origin}/fromMyIndex?typeId=${element.id}&typeHtml=${element.name}`,
                            show: element.zhuangtai === 1,
                            sort: sorts[element.name],
                        })
                    }
                }
                // 排序
                catalog.value.sort((a, b) => {
                    return a.sort - b.sort
                })
            } else {
                const { data } = await getFenleiList()
                const _array = data || []
                for (let index = 0; index < _array.length; index++) {
                    const element = _array[index]
                    if (element.id === '454222') {
                        continue
                    } else {
                        catalog.value.push({
                            name: element.name,
                            id: element.id,
                            path: `${window.location.origin}/fromMyIndex?typeId=${element.id}&typeHtml=${element.name}`,
                            show: element.zhuangtai === 1,
                        })
                    }
                }
            }
        } finally {
            if (props.type === 'sp') {
                catalog.value.unshift({
                    name: '首頁',
                    path: `${window.location.origin}/fromMyIndex?typeId=w3fefg&typeHtml=首頁`,
                })
            } else {
                catalog.value.unshift({
                    name: '首頁',
                    path: `${window.location.origin}/fromMyIndex?typeId=454222&typeHtml=首頁`,
                })
            }
        }
    }

    // 用户导航
    const subnav = ref([
        {
            name: '用戶註冊',
            icon: 'ri-user-add-fill',
            path: '/register',
            show: computed(() => isEmpty(isLogin)),
        },
        {
            name: '登錄',
            icon: 'ri-login-circle-fill',
            path: `/login?redirect=${router.currentRoute.value.fullPath}`,
            show: computed(() => isEmpty(isLogin)),
        },
    ])

    // 打开sp页面
    const openSp = () => {
        if (enableVueFront) {
            const url = router.resolve({ path: '/sp/home' }).href
            window.open(url, '_blank')
        } else {
            window.open(`${window.location.origin}/indexsp`, '_blank')
        }
    }

    // 点击菜单
    const handleClickMenu = (menu) => {
        if (enableVueFront && menu.id) {
            let name = 'Category'
            if (props.type === 'sp') {
                name = 'SpCategory'
            }
            router.push({ name: name, params: { id: menu.id } })
        } else {
            window.location.href = menu.path
        }
    }

    onMounted(() => {
        getCatalog()
    })
</script>

<style lang="scss">
    .message {
        ul {
            list-style: none;
            li {
                padding: 5px;
                @include nowrap;
            }
        }
    }
</style>
<style lang="scss" scoped>
    .el-header.header {
        position: relative;
        width: 100%;
        min-height: 100px;
        background: #ffffff;
        .container-fluid {
            display: flex;
            align-items: center;
            min-height: 100px;
            ::v-deep() {
                .logo {
                    a {
                        display: flex;
                        align-items: center;
                        img {
                            width: auto;
                            height: 44px;
                        }
                        h1 {
                            margin-left: 10px;
                            color: var(--text-normal);
                            text-align: center;
                            white-space: nowrap;
                        }
                    }
                }
                .search {
                    margin-bottom: -40px;
                    .el-input-group--prepend .el-input-group__prepend .el-select .el-select__wrapper {
                        box-shadow: none;
                        font-size: 1.4rem;
                    }
                }
                .nav {
                    flex: 1;
                    margin: 0 20px;
                    .el-menu {
                        height: 40px;
                        margin: 0 0 10px 0 !important;
                        border: none;
                        .el-menu-item {
                            border-radius: 12px 4px 12px 4px;
                            &:not(.is-active) {
                                font-size: var(--font-size-md);
                                background-color: transparent;
                                border: none;
                                border-color: transparent;
                            }
                            &.is-active {
                                color: #fff !important;
                                background: var(--linear-gradient-green);
                                border: none;
                            }
                        }
                    }
                }
                .subnav {
                    display: flex;
                    align-self: flex-end;
                    justify-content: flex-end;
                    min-width: 200px;
                    margin: 0 0 10px auto;
                    .el-menu {
                        border: none;
                        > .el-menu-item,
                        > .el-sub-menu > .el-sub-menu__title {
                            padding: 0 10px;
                            line-height: 40px;
                            border: none !important;
                            .icon {
                                width: 30px;
                                height: 30px;
                                margin-right: 3px;
                                font-size: 18px;
                                line-height: 30px;
                                color: rgba(255, 255, 255, 0.7);
                                text-align: center;
                                background: var(--linear-gradient-green);
                                border-radius: 50%;
                            }
                            &:hover {
                                background: transparent;
                                .icon {
                                    color: rgba(255, 255, 255, 1);
                                    background: var(--linear-gradient-green);
                                }
                            }
                            &:focus {
                                background: transparent;
                            }
                        }
                    }
                }
                .extnav {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    .el-badge__content {
                        border: none;
                    }
                    .el-dropdown {
                        margin-left: 30px;
                    }
                    .el-dropdown-link {
                        cursor: pointer;
                    }
                    i {
                        font-size: 18px;
                    }
                }
            }
        }
        &.dark ::v-deep() {
            color: #fff;
            // background: linear-gradient(45deg, #086f63 0%, #004178 15%);
            background: url('../../assets/images/bg-header-dark.png') center center no-repeat;
            background-size: cover;
            .logo {
                a {
                    h1 {
                        color: #fff;
                    }
                }
            }
            .nav {
                flex: 1;
                margin: 0 20px;
                .el-menu {
                    .el-menu-item {
                        &:not(.is-active) {
                            color: rgba(255, 255, 255, 0.7);
                        }
                        &:hover {
                            color: #fff !important;
                        }
                    }
                }
            }
            .subnav {
                .el-menu {
                    > .el-menu-item,
                    > .el-sub-menu > .el-sub-menu__title {
                        color: rgba(255, 255, 255, 0.7);
                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
            .extnav {
                .el-dropdown-link {
                    color: rgba(255, 255, 255, 0.7);
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
        &.fixed-header {
            box-shadow: var(--box-shadow-md);
        }
    }
</style>
